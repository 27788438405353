import React from "react";
import { Card, Table, Button, ButtonGroup, Input, Row, Col, Spinner } from "reactstrap";
import { getAccessToken } from "../../Authentication/auth";
import PurchaseRecordModal from "../../../Components/modal/PurchaseRecordModal";
import ReservationGiftModal from "../../../Components/modal/ReservationGiftModal";

import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { BACKEND_HOST } from "../../../api";
import ReservationCheckoutModal from "../../../Components/modal/ReservationCheckoutModal";
import { FiCalendar } from "react-icons/fi";
import { FaRegUser } from "react-icons/fa6";
import { IoGiftOutline } from "react-icons/io5";

const Item = styled.div`
  border-radius: 0.5vh;
  box-shadow: 1px 2px 6px 0px hsl(0deg 0% 64% / 22%);
  background-color: white;
  border: 1px solid #e5e7eb;
  /* max-width: 480px; */
  padding: 10px 20px;
  opacity: ${(props) => (props.visible ? "1" : "0.5")};
  :hover {
    cursor: ${(props) => (props.visible ? "pointer" : "default")};
  }
`;

function ReservationList({ todayOnly }) {
  const navigate = useNavigate();

  const [purchaseRecordModal, setPurchaseRecordModal] = React.useState(false);
  const [giftModal, setGiftModal] = React.useState(false);
  const [selectOrder, setSelectOrder] = React.useState({});
  const [orders, setOrders] = React.useState([]);
  const [isCancel, setIsCancel] = React.useState(false);
  const [checkoutModal, setCheckoutModal] = React.useState(false);

  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    setdata();
  }, []);

  const setdata = () => {
    const accessToken = getAccessToken();
    setIsLoading(true);
    fetch(`${BACKEND_HOST}/package/user-order/`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("fetching reservations", data);
        // 예약일이 임박한 것이 먼저 보임. 사용불가는 젤 밑에 보임.
        let _orders = data.orders
          .sort((a, b) => {
            const d1 = moment("20" + a.reservationDate);
            const d2 = moment("20" + b.reservationDate);
            return d1 > d2 ? -1 : +1;
          })
          .sort((a, b) => {
            // return !a.gifted && a.state !== "입장" ? -1 : +1
            return !a.gifted && !["입장", "퇴장", "외출", "완료", "노쇼"].includes(a.state) ? -1 : +1;
          });

        if (todayOnly) _orders = _orders.filter((item) => moment("20" + item.reservationDate).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD"));

        setOrders(_orders);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handelCancelGift = (reservId) => {
    if (window.confirm("예약상품의 선물을 취소하시겠습니까?")) {
      const accessToken = getAccessToken();
      fetch(`${BACKEND_HOST}/package/cancel-reservation-gift/${reservId}/`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          navigate(0);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  return (
    <React.Fragment>
      <Row>
        {/* <div className="d-flex justify-content-between">
                    <div>
                        <Input className="voucher-check-input-all" type="checkbox"
                            id="voucher-check-all"
                            onChange={() => {
                                toggleCheckAll()
                                changeCheckedRecords()
                            }}
                        />
                        <Label htmlFor="voucher-check-all" className="form-label fs-14" style={{ marginLeft: "6px" }}>전체선택</Label>
                    </div>
                    {modalDatas.length > 1 ? <Button onClick={() => {
                        setGiftModal(true);
                    }}>한번에 선물하기</Button> : null}
                </div> */}

        {todayOnly && orders.length ? (
          <div style={{ width: "100%", textAlign: "center", marginTop: "16px" }}>
            <h4>오늘의 예약</h4>
          </div>
        ) : null}

        <Col className="col-12 d-flex flex-wrap justify-content-center gap-4" style={todayOnly && orders.length ? { marginBottom: "16px" } : {}}>
          {isLoading ? (
            <div className="d-flex justify-content-center align-items-center" style={{ height: "50vh" }}>
              <Spinner animation="border" variant="primary" />
            </div>
          ) : null}

          {(orders || []).map((order, key) => (
            <Col lg={12} xxl={12} md={12} sm={12} xs={12} key={order.id} className="">
              <Item visible={true} className="bg-white w-md-480">
                <div
                  className="d-flex justify-content-between px-2 border-bottom"
                  onClick={() => {
                    if (!order.gifted && !["입장", "퇴장", "외출", "완료", "노쇼"].includes(order.state)) {
                      setIsCancel(false);
                      setSelectOrder(order);
                      setPurchaseRecordModal(!purchaseRecordModal);
                    } else if (!order.gifted && ["입장"].includes(order.state)) {
                      setIsCancel(false);
                      setSelectOrder(order);
                      setCheckoutModal(true);
                    }
                  }}
                >
                  <div className="card-title pt-3" style={{ fontWeight: "600" }}>
                    {order.name}
                  </div>
                  {!order.gifted && !["입장", "퇴장", "외출", "완료", "노쇼"].includes(order.state) ? (
                    <p className="fw-bold mt-2 btn btn-primary">{order.depositRequired ? "체크인/청소보증금" : "체크인"}</p>
                  ) : null}
                  {!order.gifted && ["입장"].includes(order.state) ? <p className="fw-bold mt-2 btn btn-primary">체크아웃</p> : null}
                  {!order.gifted && ["퇴장"].includes(order.state) ? <p className="text-muted pt-3">체크아웃 대기중</p> : null}
                  {order.gifted || ["완료", "노쇼"].includes(order.state) ? <p className="text-muted pt-3">사용불가</p> : null}
                </div>
                <div
                  className="d-flex py-3 px-2"
                  onClick={() => {
                    if (!order.gifted && !["입장", "퇴장", "외출", "완료", "노쇼"].includes(order.state)) {
                      setIsCancel(false);
                      setSelectOrder(order);
                      setPurchaseRecordModal(!purchaseRecordModal);
                    } else if (!order.gifted && ["입장"].includes(order.state)) {
                      setIsCancel(false);
                      setSelectOrder(order);
                      setCheckoutModal(true);
                    }
                  }}
                >
                  {/* <div className="avatar-md mx-2">
                                                <div className="avatar-title bg-soft-light text-success fs-2 rounded">
                                                    <img src={item.image} alt="" className="img-fluid" />
                                                </div>
                                            </div> */}
                  <div className="mt-1" style={{ width: "100%" }}>
                    <p className="card-text border-bottom py-2" style={{ width: "100%" }}>
                      <FiCalendar size={18} color={"#1d1d1d"} />
                      <span style={{ marginLeft: "10px", lineHeight: "32px" }}>20{order.reservationDate} </span>
                    </p>
                    <p className="card-text">
                      <FaRegUser size={16} color={"#1d1d1d"} />
                      <span style={{ marginLeft: "10px", lineHeight: "32px" }}>{order.spot}번</span>
                    </p>
                    {order.options.length ? (
                      <p className="card-text border-top py-3">
                        <span style={{ fontWeight: "600" }}>추가 옵션</span>
                        <br />
                        <br />
                        {order.options.map((item, idx, obj) => {
                          return (
                            <p key={item.id}>
                              <p className="border-bottom py-2" style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                {item.name}
                                <span style={{ textAlign: "right" }}>{item.price.toLocaleString()}원</span>
                                {/* {idx !== obj.length - 1 ? ", " : ""} */}
                              </p>
                              {/* {idx !== obj.length - 1 ? <br /> : ""} */}
                            </p>
                          );
                        })}
                      </p>
                    ) : null}
                  </div>
                </div>
                {!order.gifted && !["입장", "퇴장", "외출", "완료", "노쇼"].includes(order.state) ? (
                  <div className="d-flex w-100 flex-column pb-3">
                    {/* <div className="d-flex justify-content-center gap-4 mb-3">
                                        <Button
                                            color="light"
                                            onClick={(e) => {
                                                navigate(`/add-options-detail/${order.id}`)
                                            }}
                                        >
                                            옵션추가
                                        </Button>

                                        <Button
                                            color="danger"
                                            style={order.options.length ? {} : { visibility: "hidden" }}
                                            onClick={(e) => {
                                                navigate(`/cancel-options-detail/${order.id}`)
                                            }}
                                        >
                                            옵션취소
                                        </Button>
                                    </div> */}

                    <div className="d-flex gap-2 pb-2" style={{ justifyContent: "flex-end" }}>
                      <Button
                        style={{ fontFamily: "Gmarket-M", backgroundColor: "#fff", borderColor: "#ff5a5f", color: "#ff5a5f" }}
                        onClick={(e) => {
                          setIsCancel(false);
                          setSelectOrder(order);
                          setGiftModal(true);
                        }}
                      >
                        선물하기
                      </Button>
                      <Button
                        style={{ fontFamily: "Gmarket-M", backgroundColor: "#ff5a5f", borderColor: "#ff5a5f" }}
                        onClick={(e) => {
                          setIsCancel(true);
                          setSelectOrder(order);
                          setPurchaseRecordModal(true);
                        }}
                      >
                        예약취소
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div className="d-flex w-100 align-items-center pb-3 border-top pt-3 px-2 justify-content-between">
                    {order.gift && order.gift["username"] ? (
                      <div className="card-text">
                        <IoGiftOutline size={18} color="#1d1d1d" style={{ marginRight: "5px" }} /> {order.gift["username"]}
                      </div>
                    ) : null}
                    {!!order.gifted ? <span style={{ fontFamily: "Gmarket-M", color: "#ff5a5f" }}>선물완료</span> : null}
                    {/* <span style={{ margin: "8px" }}></span> */}
                    {order.gifted === "선물발신" ? (
                      <Button
                        color="danger"
                        onClick={(e) => {
                          handelCancelGift(order.id);
                        }}
                      >
                        선물취소
                      </Button>
                    ) : null}
                  </div>
                )}
              </Item>
            </Col>
          ))}

          {!isLoading && orders.length === 0 && !todayOnly ? (
            <div className="d-flex justify-content-center" style={{ alignItems: "center", height: 150 }}>
              예약내역 없음
            </div>
          ) : null}
        </Col>
      </Row>
      <PurchaseRecordModal
        isCancel={isCancel}
        setVisible={setPurchaseRecordModal}
        visible={purchaseRecordModal}
        info={selectOrder}
        setInfo={setSelectOrder}
        navigate={navigate}
        needRefresh={setdata}
      />
      <ReservationGiftModal setVisible={setGiftModal} visible={giftModal} info={selectOrder} setInfo={setSelectOrder} navigate={navigate} needRefresh={setdata} />
      <ReservationCheckoutModal setVisible={setCheckoutModal} visible={checkoutModal} info={selectOrder} setInfo={setSelectOrder} navigate={navigate} needRefresh={setdata} />
    </React.Fragment>
  );
}

export default ReservationList;
