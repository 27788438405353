import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, CardBody, Row, Col, Button, Table } from "reactstrap";
import { BACKEND_HOST } from "../../../api";

import Navbar from "../../../Components/Common/navbar";
import { getAccessToken } from "../../Authentication/auth";

const AddOptionsOrderDone = () => {
  const navigate = useNavigate();
  const { orderId } = useParams();

  const [reservationOptions, setReservationOptions] = React.useState([]);

  React.useEffect(() => {
    console.log("orderId", orderId);
    if (!orderId) return;

    const accessToken = getAccessToken();
    if (accessToken) {
      fetch(`${BACKEND_HOST}/package/option-list/?order_id=${orderId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("option-list", data);
          setReservationOptions(data.reservation_options);
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      // navigate(`/`);
    }
  }, [orderId]);

  // "id": _option.id,
  // "name": _option.option.name,
  // "package_name": _option.reservation.spot.package.name,
  // "spot": _option.reservation.spot.name,
  // "price": _option.price,
  // "reservation_date": str(_option.reservation.start_date)[2:],
  // "order_date": str(_option.order.order_date)[2:10],

  return (
    <React.Fragment>
      <div>
        <Navbar />
        <h2 className="border-success text-center my-3 fw-bold">주문완료</h2>
        <Card>
          <CardBody>
            <Row>
              <Col lg={12}>
                {reservationOptions.length && (
                  <div className="mb-4">
                    <h5 className="mb-2">{reservationOptions[0].package_name} 추가 옵션 결재</h5>
                    <span className="mb-1 fw-semibold d-block text-muted text-uppercase">자리: {reservationOptions[0].spot}</span>
                    <span className="mb-1 fw-semibold d-block text-muted text-uppercase">예약일: {reservationOptions[0].reservation_date}</span>
                    <span className="mb-1 fw-semibold d-block text-muted text-uppercase">결제일: {reservationOptions[0].order_date}</span>
                  </div>
                )}
                <div className="table-responsive">
                  <Table className="table-borderless text-center mb-0">
                    <thead className="table-light">
                      <tr>
                        <th scope="col">상품명</th>
                        {/* <th scope="col">자리</th> */}
                        {/* <th scope="col">예약날짜</th> */}
                        {/* <th scope="col">결제일</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {(reservationOptions || []).map((_option, key) => (
                        <tr key={key}>
                          <td>{_option.name}</td>
                          {/* <td className="d-flex justify-content-center">
                                                        <div>{_option.spot} &nbsp;</div>
                                                    </td>
                                                    <td>{_option.reservation_date}</td>
                                                    <td>{_option.order_date}</td> */}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <div className="d-flex justify-content-center">
          <Button onClick={() => navigate("/my-page")} color="success">
            주문확인하러 가기
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddOptionsOrderDone;
