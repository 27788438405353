import React, { useState } from "react";
import styled from "styled-components";

import { GoogleApiWrapper, Map, Marker } from "google-maps-react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { Collapse, NavbarToggler, NavLink } from "reactstrap";

//Import Breadcrumb
import BreadCrumb from "./BreadCrumb";
import KakaoMap from "./KaKaoMap";
import Navbar from "../../Components/Common/navbar";
import Footer from "../Landing/OnePage/footer";
import { getAccessToken } from "../Authentication/auth";
import { BACKEND_HOST } from "../../api";
import { useUserStore } from "../../store/store";

const LoadingContainer = () => <div>Loading...</div>;

const CampMap = (props) => {
  const [permission] = useUserStore((state) => [state.permission]);

  document.title = "캠프MAP";

  return (
    <React.Fragment>
      <Navbar activeBar={4} />
      <KakaoMap permission={permission} />
    </React.Fragment>
  );
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE",
  LoadingContainer: LoadingContainer,
  v: "3",
})(CampMap);
