import React, { useMemo, useState } from "react";
import { Container, Row, Col, Card, CardHeader, Label, Button, Modal, ModalBody, ModalHeader, CardBody } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { getAccessToken } from "../../Authentication/auth";
import { BACKEND_HOST } from "../../../api";
import moment from "moment";
import Loader from "../../../Components/Common/Loader";
import CommentList from "./CommentList";
import { useUserStore } from "../../../store/store";
import PrevInquiryListSection from "./PrevInquiryListSection";
import { IoArrowBackOutline } from "react-icons/io5";
import { IoIosArrowBack } from "react-icons/io";
import Navbar from "../../../Components/Common/navbar";
import styled from "styled-components";

const InquiryContent = styled.div`
  max-width: 600px;
  & > p > img {
    width: 100%;
  }
  & > p {
    width: 100%;
  }
`;

const InquiryDetailSection = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const params = new URLSearchParams(window.location.search);
  const page = params.get("page") || 0;

  const user = useUserStore((state) => state.user);

  const [inquiry, setInquiry] = React.useState(null);

  const [permission] = useUserStore((state) => [state.permission]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [deleteModal, setDeleteModal] = React.useState(false);

  function togDeleteModal() {
    setDeleteModal(!deleteModal);
  }

  const deleteData = () => {
    const accessToken = getAccessToken();
    if (accessToken) {
      fetch(`${BACKEND_HOST}/inquiry/article-write/${id}/`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        // .then((response) => response.json())
        .then((data) => {
          // setInquiry(data);
          console.log(data);
          navigate(`/inquiry`);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const setExtraInfo = (articleId, need, extraInfo) => {
    const accessToken = getAccessToken();
    if (accessToken) {
      fetch(`${BACKEND_HOST}/inquiry/extra-info/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          article_id: articleId,
          need_extra_info: need,
          extra_info: extraInfo,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          refetch(id);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const refetch = (id) => {
    setLoading(true);
    setError(null);
    const accessToken = getAccessToken();
    fetch(`${BACKEND_HOST}/inquiry/articles/${id}/`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.error) {
          setError(data.error);
        } else setInquiry(data);
      })
      .catch((e) => {
        console.log(e);
        setError(e);
      })
      .finally(() => setLoading(false));
  };

  React.useEffect(() => {
    refetch(id);
  }, [id]);

  const article_username = useMemo(() => {
    let _username = "";
    if (inquiry) {
      if ((!!permission && permission.permission) || (user && inquiry.user_id == user.id)) {
        _username = inquiry.username;
      } else {
        _username = `${inquiry.username[0] + "*".repeat(inquiry.username.length - 1)}`;
      }
      if (!!inquiry.user_permission?.permission && !!permission && permission.permission) {
        _username = `관리자(${_username})`;
      }
      if (!!inquiry.user_permission?.permission && (!permission || !permission.permission)) {
        _username = `관리자`;
      }
    }
    return _username;
  }, [inquiry]);

  if (loading)
    return (
      <Container>
        <Row className="mt-4 justify-content-center">
          <Loader loading={loading} />
        </Row>
      </Container>
    );
  if (!inquiry || error)
    return (
      <Container>
        <Navbar />
        <Row className="justify-content-center" style={{ minHeight: "100vh" }}>
          <p style={{ margin: "100px auto", fontFamily: "GMarket-M", textAlign: "center" }}>글쓴이 혹은 관리자만 글을 볼 수 있습니다.</p>
        </Row>
      </Container>
    );
  return (
    <React.Fragment>
      <div className="mt-1">
        <Container>
          <div style={{ backgroundColor: "#fff", padding: "10px 0" }} className="sticky-top">
            <Button
              color="white"
              className="add-btn"
              style={{ backgroundColor: "transparent", boxShadow: "none", border: "none" }}
              onClick={() => {
                navigate(`/inquiry?page=${page}`);
              }}
              id="edit-btn"
            >
              <IoArrowBackOutline size={26} />
            </Button>
            <h4
              style={{
                fontSize: "16px",
                fontFamily: "Gmarket-M",
                position: "absolute",
                margin: "0 auto",
                marginBottom: "0",
                textAlign: "center",
                left: "49%",
                top: "50%",
                transform: "translate(-50%,-50%)",
              }}
            >
              문의글
            </h4>
          </div>

          <Row className="justify-content-center" style={{ minHeight: "100vh" }}>
            <Col>
              <Card id="customerList">
                <CardHeader className="border-0" style={{ padding: "20px 10px" }}>
                  <Row className="g-4 d-flex flex-row align-items-center justify-content-between">
                    <div className="" style={{ width: "calc(100%)" }}>
                      <div>
                        <h2 className="mb-2 ff-secondary fw-semibold lh-base" style={{ fontSize: "22px" }}>
                          {inquiry.title}
                        </h2>
                        <p style={{ whiteSpace: "pre-wrap", color: "gray", fontSize: "14px" }}>{`${moment(inquiry.written_date).format("YYYY-MM-DD HH:mm")}`}</p>

                        <Row className="g-4 align-items-center">
                          <div className="col-sm">
                            {/* <p style={{ whiteSpace: "pre-wrap", color: "gray" }}>{`${moment(inquiry.written_date).format("YYYY-MM-DD HH:mm")}\n`}</p> */}
                            <p style={{ whiteSpace: "pre-wrap" }}>
                              <span>
                                <img
                                  src={inquiry.user_permission?.permission ? "/amazingpark-logo.png" : inquiry.profile_img || `${BACKEND_HOST}/media/ProfileBlank.png`}
                                  alt=""
                                  className="rounded-circle"
                                  style={{ width: "30px", height: "30px", objectFit: "cover", marginRight: "8px" }}
                                />
                              </span>
                              <span className="" style={{ color: "#6d6d6d", fontSize: "15px", marginTop: "10px" }}>
                                {article_username}
                              </span>
                            </p>
                            {user && (user.permission === "스탭" || user.permission === "관리자") && inquiry.phone_num && (
                              <p style={{ whiteSpace: "pre-wrap" }}>
                                연락처: <a href={`tel:${inquiry.phone_num}`}>{inquiry.phone_num}</a>
                              </p>
                            )}
                            {inquiry.address && <p style={{ whiteSpace: "pre-wrap" }}>{`문의주소: ${inquiry.address}`}</p>}

                            {permission?.permission && inquiry.need_extra_info ? (
                              <p className="text-danger" style={{ whiteSpace: "pre-wrap" }}>{`주의: ${inquiry.extra_info ? inquiry.extra_info : "주의가 필요합니다."}`}</p>
                            ) : null}
                          </div>
                        </Row>
                      </div>
                    </div>
                  </Row>

                  {(!!permission && permission.permission > 0) || (user && user.id === inquiry.user_id) ? (
                    <div className="" style={{ width: "100%" }}>
                      <div className="d-flex justify-content-end gap-2">
                        {!!permission && permission.permission > 0 ? (
                          <>
                            {!inquiry.need_extra_info ? (
                              <Button
                                size="sm"
                                className="btn-soft-danger add-btn me-1"
                                style={{ boxShadow: "none", border: "none", fontFamily: "GMarket-M", padding: "6px 10px" }}
                                onClick={() => {
                                  const result = window.prompt("주의할 내용을 입력해주세요.");
                                  setExtraInfo(inquiry.id, true, result);
                                }}
                                id="inquiry-alert-btn"
                              >
                                주의!
                              </Button>
                            ) : (
                              <Button
                                size="sm"
                                className="btn-soft-success add-btn me-1"
                                style={{ boxShadow: "none", border: "none", fontFamily: "GMarket-M", padding: "6px 10px" }}
                                onClick={() => {
                                  setExtraInfo(inquiry.id, false, null);
                                }}
                                id="inquiry-alert-btn"
                              >
                                주의 X
                              </Button>
                            )}
                          </>
                        ) : null}
                        <Button
                          size="sm"
                          className="btn-soft-success add-btn me-1"
                          style={{ boxShadow: "none", border: "none", fontFamily: "GMarket-M", padding: "6px 10px" }}
                          onClick={() => {
                            navigate(`/inquiry/form/${id}`);
                          }}
                          id="edit-btn"
                        >
                          수정
                        </Button>
                        <Button
                          size="sm"
                          className="btn-soft-danger add-btn me-1"
                          style={{ boxShadow: "none", border: "none", fontFamily: "GMarket-M" }}
                          onClick={() => {
                            togDeleteModal();
                          }}
                          id="delete-btn"
                        >
                          삭제
                        </Button>
                      </div>
                    </div>
                  ) : null}
                </CardHeader>

                <CardBody>
                  <br />
                  {/* <br /> */}
                  {/* <hr /> */}
                  <Row className="g-4 align-items-center mb-4">
                    {inquiry.images
                      ? inquiry.images.map((image) => (
                          <div key={image.id}>
                            <img src={`${image.image}`} style={{ maxWidth: "600px", width: "100%" }} alt="inquiryimg" />
                          </div>
                        ))
                      : null}
                  </Row>
                  <Row className="g-4 align-items-center" style={{ maxWidth: "600px" }}>
                    <InquiryContent dangerouslySetInnerHTML={{ __html: inquiry.content }} style={{ maxWidth: "600px" }} />
                  </Row>
                  {!(inquiry.mode === "4") ? (
                    <Row className="" style={{ marginTop: "50px" }}>
                      <CommentList articleId={inquiry.id} />
                    </Row>
                  ) : (
                    <Row className="mt-4">
                      <div style={{ margin: "20px" }}>
                        <span></span>
                      </div>
                    </Row>
                  )}

                  {/* <Row className="g-4 align-items-center">
                    <div className="col-sm-3 ms-auto">
                      <div className="d-flex justify-content-sm-end gap-2">
                        <Button
                          color="white"
                          className="btn-ghost-dark add-btn me-1"
                          onClick={() => {
                            navigate(`/inquiry?page=${page}`);
                          }}
                          id="edit-btn"
                        >
                          목록보기
                        </Button>
                      </div>
                    </div>
                  </Row> */}
                </CardBody>

                {!!permission && permission.permission > 0 ? (
                  <div>
                    <br />
                    <br />
                    <span className="form-label mx-2" style={{ fontFamily: "Gmarket-M", color: "#6d6d6d" }}>
                      {inquiry.username}님의 이전 문의글
                    </span>
                    <PrevInquiryListSection targetUser={inquiry.phone_num} />
                  </div>
                ) : null}
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Delete Modal */}
      <Modal
        id="topmodal"
        isOpen={deleteModal}
        toggle={() => {
          togDeleteModal();
        }}
      >
        <ModalHeader
          className="modal-title"
          id="myModalLabel"
          toggle={() => {
            togDeleteModal();
          }}
        >
          삭제하기
        </ModalHeader>
        <ModalBody className="text-center p-5">
          <lord-icon src="https://cdn.lordicon.com/gsqxdxog.json" trigger="loop" style={{ width: "120px", height: "120px" }} colors="primary:#f7b84b,secondary:#f06548"></lord-icon>
          <div className="mt-4">
            <h5 className="mb-3">해당 글을 삭제하시겠습니까?</h5>
            {/* <p className="text-muted mb-4"> The transfer was not successfully received by us. the email of the recipient wasn't correct.</p> */}
            <div className="hstack gap-2 justify-content-center">
              <button
                className="btn bg-gradient-light fw-medium"
                onClick={() => {
                  togDeleteModal();
                }}
              >
                <i className="ri-close-line me-1 align-middle"></i>닫기
              </button>
              <button
                className="btn btn-danger"
                onClick={() => {
                  deleteData();
                  setDeleteModal(false);
                }}
              >
                삭제하기
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default InquiryDetailSection;
