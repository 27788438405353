import React, { useEffect, useRef } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { Card, CardBody, Col, Container, Input, Label, Row, Button, Form, FormFeedback, Alert } from "reactstrap";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import logoLight from "../../assets/images/camp-logo.png";
import { LoginTitleImgModal } from "../../Components/Common/ManagerModal";

// kakao
import { REST_API_KEY, REDIRECT_URI } from "../../common/social/kakao/api";
import KAKAO_BTN from "../../assets/social/kakao_btn.png";
// naver
// import { CLIENT_ID, CALLBACK_URL, STATE_STRING } from '../../common/social/naver/api';

import { SITE_UID } from "../../assets/meta/meta";
import { SITE_ID } from "../../assets/meta/meta";
import { isUserLogin, setAccessToken } from "./auth";
import { BACKEND_HOST } from "../../api";

const KAKAO_AUTH_URL = `https://kauth.kakao.com/oauth/authorize?client_id=${REST_API_KEY}&redirect_uri=${REDIRECT_URI}&response_type=code&state=${SITE_UID}`;
// const NAVER_AUTH_URL = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${CLIENT_ID}&state=${STATE_STRING}&redirect_uri=${CALLBACK_URL}`;

const Login = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  /* 값을 index의 permission을 navbar를 통해 가지고 옴 */
  const [permission, setPermission] = React.useState(location.state ? location.state.citation : 0);
  const [loginTitleEditModal, setLoginTitleEditModal] = React.useState(false);

  const [responseAuthNum, setResponseAuthNum] = React.useState(null);
  const [siteName, setSiteName] = React.useState("");
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      phoneNum: "",
      authNum: 0,
    },
    validationSchema: Yup.object({
      phoneNum: Yup.number().positive().integer().required("전화번호를 입력해주세요.('-' 제외)"),
      authNum: Yup.number().test("check-authnum", "올바른 인증번호를 입력해주세요.", (val, ctx) => {
        return responseAuthNum === null || responseAuthNum.toString() === val.toString();
      }),
    }),
    onSubmit: (values) => {
      let body = { phone_num: "0" + values.phoneNum.toString() };
      if (responseAuthNum === null) {
        fetch(`${BACKEND_HOST}/auth/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        })
          .then((response) => response.json())
          .then((data) => {
            setResponseAuthNum(data["auth_num"]);
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        fetch(`${BACKEND_HOST}/api/token/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        })
          .then((response) => response.json())
          .then((data) => {
            localStorage.setItem("refresh_token", data["refresh_token"]);
            setAccessToken(data["access_token"]);

            // state 보고 라우팅
            if (!location.state) navigate(`/`);
            else if (location.state.from) navigate(location.state.from);
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
  });

  useEffect(() => {
    fetch(`${BACKEND_HOST}/sites/camps/${SITE_ID}/`)
      .then((response) => response.json())
      .then((data) => {
        setSiteName(data.name);
        console.log("get site", data);
      })
      .catch((e) => console.log(e));
  }, []);

  // React.useEffect(() => {
  //     const { naver } = window

  //     const naverLogin = new naver.LoginWithNaverId(
  //       {
  //         clientId: CLIENT_ID,
  //         callbackUrl: CALLBACK_URL,
  //         isPopup: false, /* 팝업을 통한 연동처리 여부, true 면 팝업 */
  //         loginButton: { color: "green", type: 3, height: 47, width: "100%" } /* 로그인 버튼의 타입을 지정 */
  //       }
  //     );

  //     naverLogin.init();
  // }, []);

  React.useEffect(() => {
    // 이미 로그인한 상태에서 잘못된 접근을 막는 코드
    if (isUserLogin() && permission <= 1) {
      navigate(`/`);
    }
  }, []);

  return (
    <React.Fragment>
      <div className="layout-wrapper landing" style={{ minHeight: "100vh", backgroundColor: "#fff9fa" }}>
        <div style={{ maxWidth: "640px", margin: "0 auto", backgroundColor: "#fff", minHeight: "100vh" }}>
          <div className="auth-page-content">
            <Container>
              <Row>
                <Col lg={12}>
                  <div className="text-center mt-sm-5 mb-4 text-white-50 pt-4">
                    <div>
                      <Link to="/" className="d-inline-block auth-logo">
                        <img src={`${BACKEND_HOST}/static/logo/amazingcamp_logo_450.png`} alt="" height="150" />
                      </Link>
                    </div>
                  </div>
                  {!!permission && permission >= 2 ? (
                    <Button color="primary" className="btn mb-3 border-0 position-absolute top-35 end-0" style={{ zIndex: "2" }} onClick={() => setLoginTitleEditModal(!loginTitleEditModal)}>
                      로그인 타이틀 이미지 수정
                    </Button>
                  ) : null}
                </Col>
              </Row>

              <Row className="justify-content-center">
                <Col md={12} lg={12} xl={12}>
                  <Card>
                    <CardBody className="p-4">
                      <div className="text-center mt-2">
                        <h5 className="text-primary fw-bold" style={{ fontFamily: "Gmarket-M" }}>
                          {siteName}
                        </h5>
                        <p className="text-muted">{siteName}에 어서오세요!</p>
                      </div>
                      {/* {error && error ? (<Alert color="danger"> {error} </Alert>) : null} */}
                      <div className="p-2 mt-4">
                        <Form
                          onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                          }}
                          action="#"
                        >
                          <div className="mt-4 text-center">
                            <div className="signin-other-title">
                              <h5 className="fs-18 mb-4 title">카카오 1초 로그인 / 회원가입</h5>
                            </div>
                            <div>
                              <button
                                style={{ border: 0, backgroundColor: "#fee500", width: "100%", borderRadius: 12 }}
                                onClick={() => {
                                  if (!location.state) window.location.replace(KAKAO_AUTH_URL);
                                  else if (location.state.from === `/reception/2`) window.location.replace(KAKAO_AUTH_URL + "--V");
                                  else if (location.state.from === `/purchases`) window.location.replace(KAKAO_AUTH_URL + "--P");
                                }}
                              >
                                <img className="img-fluid" src={KAKAO_BTN} alt="" />
                              </button>
                            </div>

                            <br />
                          </div>
                        </Form>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
          <LoginTitleImgModal setVisible={setLoginTitleEditModal} visible={loginTitleEditModal} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Login;
