import React, { useEffect, useState } from "react";
import { Container, Form, Row, Col, Card, CardBody, CardHeader, Nav, NavItem, NavLink, TabContent, TabPane, Label, Input, Table, Modal } from "reactstrap";
import classnames from "classnames";
import { useNavigate, useLocation } from "react-router-dom";
import jwtDecode from "jwt-decode";

import BgFrame from "../../../Components/Common/BgFrame";

import tossPayments from "../../../common/payments/toss/api";
import { SUBDOMAIN } from "../../../assets/meta/meta";
import { getAccessToken } from "../../Authentication/auth";
import { BACKEND_HOST } from "../../../api";
import DaumPostcode from "react-daum-postcode";

const ShoppingOrderProcess = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [width, setWidth] = React.useState(null);

  const [user, setUser] = useState({
    phoneNum: null,
  });
  const [name, setName] = useState("");
  const [cancelRuleAgree, setCancelRuleAgree] = React.useState(false);
  const [purchaseAllAgree, setPurchaseAllAgree] = React.useState(false);
  const [purchaseOneAgree, setPurchaseOneAgree] = React.useState(false);

  const [activeTab, setactiveTab] = useState(1);
  const [passedSteps, setPassedSteps] = useState([1]);

  const [addressPopupOpen, setAddressPopupOpen] = useState(false);
  const [address, setAddress] = useState("");
  const [addressDetail, setAddressDetail] = useState("");
  // const [recipient, setRecipient] = useState("");
  // const [recipientPhone, setRecipientPhone] = useState("");
  const [memo, setMemo] = useState("");

  const toggleAllAgree = (agree) => {
    setPurchaseAllAgree(agree);
    setPurchaseOneAgree(agree);
  };
  const togglePurchaseAgreeOptions = (agree) => {
    // 임시 코드 => 하위 동의항목이 더 생기면 바꿔야함.
    setPurchaseAllAgree(agree);
    setPurchaseOneAgree(agree);
  };

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab];

      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab);
        setPassedSteps(modifiedSteps);
      }
    }
  };
  const totalOrderPrice = () => {
    if (location.state.products.free_delivery_condition > 0) {
      if (location.state.products.map((item) => item.price * item.quantity).reduce((prev, curr) => prev + curr, 0) < location.state.products.free_delivery_condition) {
        return location.state.products.map((item) => item.price * item.quantity).reduce((prev, curr) => prev + curr, 0) + location.state.products.delivery_fee;
      }
    } else {
      return location.state.products.map((item) => item.price * item.quantity).reduce((prev, curr) => prev + curr, 0);
    }
  };

  const totalOrderPriceWithVariation = () => {
    let _orderPrice = 0;
    let _total = location.state.products
      .flatMap((item) => {
        if (item.selectVariation)
          return item.selectVariation.map((variation) => {
            if (variation.is_required) return (item.price + variation.price) * variation.quantity;
            return variation.price * variation.quantity;
          });
        return item.price * item.quantity;
      })
      .reduce((prev, curr) => prev + curr, 0);
    console.log("33", _total);

    location.state.products.forEach((product) => {
      if (product.free_delivery_condition > 0) {
        if (_total < product.free_delivery_condition) {
          console.log("44", _total);
          _orderPrice = _total += product.delivery_fee;
        } else {
          console.log("55", _total);
          _orderPrice = _total;
        }
      } else {
        console.log("66", _total);
        _orderPrice = _total;
      }
    });

    return _orderPrice;
  };

  useEffect(() => {
    console.log("location.state:", location.state);
    console.log("totalOrderPrice:", totalOrderPriceWithVariation());
  }, []);

  const variationProcessPayment = () => {
    const req_data = {
      cart_data: location.state.products.flatMap((item) =>
        item.selectVariation.map((variation) => {
          return { variation: variation.id, shopping_product: item.id, quantity: variation.quantity };
        })
      ),
      // cart_data: location.state.products[0].selectVariation.map((variation) => {
      //   return { ...location.state.products[0], variation: variation.id, shopping_product: location.state.products[0].id, quantity: variation.quantity };
      // }),
      address: address + " " + addressDetail,
      recipient: name,
      phone_num: user.phoneNum,
      memo: memo,
    };
    console.log("order data:", req_data);
    const accessToken = getAccessToken();

    if (!accessToken) {
      alert("로그인이 필요한 서비스입니다.");
      navigate(`/login`);
      return;
    }

    fetch(`${BACKEND_HOST}/shop/preorder/`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      // body: { shopping_product: location.state.product.id, quantity: location.state.quantity }
      body: JSON.stringify(req_data),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("data >>> ", data);
        if ("error" in data) {
          alert(data.error);
          navigate(`/shop`);
          return;
        }
        // 결제창 닫았을 때 catch error하기
        tossPayments.requestPayment("카드", {
          amount: totalOrderPriceWithVariation(),
          orderId: data.orderId,
          orderName: `티켓 종합`,
          customerName: name,
          successUrl: `${BACKEND_HOST}/shop/order/`,
          failUrl: `https://${SUBDOMAIN}.amazingticket.site/shop`,
        });
      })
      .catch((e) => {
        console.log(e);
        alert("지금은 구매가능 시간이 아니거나 품절된 상품입니다.");
        navigate(`/shop`);
      });
  };

  const processPayment = () => {
    const req_data = {
      cart_data: location.state.products.map((item) => ({
        ...item,
        shopping_product: item.id,
        quantity: item.quantity,
      })),
      address: address + " " + addressDetail,
      recipient: name,
      phone_num: user.phoneNum,
      memo: memo,
    };
    console.log("order data:", req_data);
    const accessToken = getAccessToken();

    if (!accessToken) {
      alert("로그인이 필요한 서비스입니다.");
      navigate(`/login`);
      return;
    }

    fetch(`${BACKEND_HOST}/shop/preorder/`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      // body: { shopping_product: location.state.product.id, quantity: location.state.quantity }
      body: JSON.stringify(req_data),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("data >>> ", data);
        if ("error" in data) {
          alert(data.error);
          navigate(`/shop`);
          return;
        }
        // 결제창 닫았을 때 catch error하기
        tossPayments.requestPayment("카드", {
          amount: totalOrderPrice(),
          orderId: data.orderId,
          orderName: `티켓 종합`,
          customerName: name,
          successUrl: `${BACKEND_HOST}/shop/order/`,
          failUrl: `https://${SUBDOMAIN}.amazingticket.site/shop`,
        });
      })
      .catch((e) => {
        console.log(e);
        alert("지금은 구매가능 시간이 아니거나 품절된 상품입니다.");
        navigate(`/shop`);
      });
  };

  const logOut = () => {
    alert("허용되지 않은 접근입니다! 다시 로그인 해주세요.");
  };

  React.useEffect(() => {
    const accessToken = getAccessToken();
    if (accessToken) {
      let decoded = {};
      try {
        decoded = jwtDecode(accessToken);
      } catch (e) {
        console.log(e);
        logOut();
      }

      const userId = decoded["user_id"];
      fetch(`${BACKEND_HOST}/accounts/users/${userId}/`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (!data["전화번호"]) {
            logOut();
          } else {
            setUser({ phoneNum: data["전화번호"], name: data["이름"] });
            setName(data["이름"]);
          }
        })
        .catch((e) => {
          logOut();
        });
    } else {
      setUser({
        phoneNum: null,
      });
    }
  }, [location]);

  React.useEffect(() => {
    setWidth(window.innerWidth);
  }, []);

  return (
    <BgFrame>
      <React.Fragment>
        <div>
          <Container fluid>
            <Row>
              <Col xl={12}>
                <Card style={{ boxShadow: "5px 4px 8px 5px #f6f6f6" }}>
                  <CardBody className="checkout-tab">
                    <Form action="#">
                      <div className="step-arrow-nav mt-n3 mx-n3 mb-3">
                        <Nav className="nav-pills nav-justified custom-nav" role="tablist">
                          <NavItem role="presentation">
                            <NavLink href="#" className={classnames({ active: activeTab === 1, done: activeTab <= 4 && activeTab >= 0 }, "fs-15 p-3")} onClick={() => {}}>
                              <i className="ri-user-2-line fs-16 p-2 bg-soft-primary text-primary rounded-circle align-middle me-2"></i>
                              {width < 400 ? null : "회원정보"}
                            </NavLink>
                          </NavItem>
                          <NavItem role="presentation">
                            <NavLink href="#" className={classnames({ active: activeTab === 2, done: activeTab <= 4 && activeTab > 1 }, "fs-15 p-3")} onClick={() => {}}>
                              <i className="ri-truck-line fs-16 p-2 bg-soft-primary text-primary rounded-circle align-middle me-2"></i>
                              {width < 400 ? null : "상품정보"}
                            </NavLink>
                          </NavItem>
                          <NavItem role="presentation">
                            <NavLink href="#" className={classnames({ active: activeTab === 3, done: activeTab <= 4 && activeTab > 2 }, "fs-15 p-3")} onClick={() => {}}>
                              <i className="ri-bank-card-line fs-16 p-2 bg-soft-primary text-primary rounded-circle align-middle me-2"></i>
                              {width < 400 ? null : "결제하기"}
                            </NavLink>
                          </NavItem>
                        </Nav>
                      </div>

                      <TabContent activeTab={activeTab}>
                        <TabPane tabId={1} id="pills-bill-info">
                          <div>
                            <h5 className="mb-1" style={{ fontFamily: "Gmarket-M" }}>
                              구매정보
                            </h5>
                            <p className="mb-4" style={{ color: "#f6475f", fontWeight: 500 }}>
                              {name || user.phoneNum ? "구매자 성함을 입력해주세요." : "로그인을 해주세요."}
                            </p>
                          </div>

                          <div>
                            <Row>
                              <Col sm={6}>
                                <div className="mb-3">
                                  <Label htmlFor="billinginfo-firstName" className="form-label">
                                    구매자
                                  </Label>
                                  <Input
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    type="text"
                                    className="form-control"
                                    id="billinginfo-firstName"
                                    placeholder="구매자명"
                                    disabled={!user.phoneNum}
                                  />
                                </div>
                              </Col>

                              <Col sm={6}>
                                <div className="mb-3">
                                  <Label htmlFor="billinginfo-phone" className="form-label">
                                    휴대폰번호
                                  </Label>
                                  <Input value={user.phoneNum ?? ""} type="text" className="form-control" id="billinginfo-phone" placeholder="휴대폰번호" disabled />
                                </div>
                              </Col>

                              <Col sm={12}>
                                <Modal
                                  toggle={() => {
                                    setAddressPopupOpen((prev) => !prev);
                                  }}
                                  isOpen={addressPopupOpen}
                                  modalClassName="fadeInUp"
                                  centered
                                >
                                  <div className="modal-header">
                                    <h5 className="modal-title mt-0">주소검색</h5>
                                    <button
                                      type="button"
                                      onClick={() => {
                                        setAddressPopupOpen(false);
                                      }}
                                      className="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                  <div className="modal-body">
                                    <DaumPostcode
                                      style={{}}
                                      onComplete={(data) => {
                                        setAddress(data.address);
                                        setAddressPopupOpen(false);
                                      }}
                                    ></DaumPostcode>
                                  </div>
                                </Modal>

                                <div className="mb-3">
                                  <Label htmlFor="billinginfo-address" className="form-label">
                                    배송지 주소
                                  </Label>
                                  <div style={{ width: "100%", display: "flex", flexDirection: "row", gap: "8px" }}>
                                    <div style={{ flexGrow: 1, flexShrink: 0 }}>
                                      <Input
                                        value={address}
                                        style={{ width: "100%", minWidth: "100%" }}
                                        type="text"
                                        className="form-control"
                                        id="billinginfo-address"
                                        placeholder="배송지 주소"
                                        disabled
                                      />
                                    </div>
                                    <div style={{ flexGrow: 0, flexShrink: 1 }}>
                                      <button
                                        style={{ fontFamily: "Gmarket-M", backgroundColor: "#f6475f", color: "#fff" }}
                                        type="button"
                                        className="btn btn-label right ms-auto nexttab"
                                        onClick={() => {
                                          setAddressPopupOpen(true);
                                        }}
                                      >
                                        <i className="ri-search-line label-icon align-middle" style={{ backgroundColor: "#f6475f", color: "#fff" }}></i>
                                        주소검색
                                      </button>
                                    </div>
                                  </div>
                                  <div style={{ height: "10px" }}></div>
                                  <Input
                                    value={addressDetail}
                                    onChange={(e) => setAddressDetail(e.target.value)}
                                    type="text"
                                    className="form-control"
                                    id="billinginfo-address-detail"
                                    placeholder="상세주소"
                                  />
                                  <div style={{ height: "10px" }}></div>
                                  <Input value={memo} onChange={(e) => setMemo(e.target.value)} type="text" className="form-control" id="billinginfo-memo" placeholder="배송 메모" />
                                  <div style={{ fontSize: "14px", color: "gray", marginTop: "10px" }}>예) 지점 방문수령으로 oo월 oo일 수령을 원합니다.</div>
                                </div>
                              </Col>
                            </Row>

                            <div className="d-flex align-items-start gap-3 mt-3">
                              <button
                                type="button"
                                style={{ fontFamily: "Gmarket-M", backgroundColor: "#f6475f", color: "#fff" }}
                                className="btn btn-label right ms-auto nexttab"
                                onClick={() => {
                                  if (!(name && user.phoneNum) || !address) {
                                    alert("구매자명 및 주소를 입력해주세요.");
                                    return;
                                  }
                                  toggleTab(activeTab + 1);
                                  if (name !== user.name) {
                                    fetch(`${BACKEND_HOST}/accounts/set-username/`, {
                                      method: "POST",
                                      headers: {
                                        "Content-Type": "application/json",
                                        Authorization: `Bearer ${getAccessToken()}`,
                                      },
                                      body: JSON.stringify({ name: name }),
                                    })
                                      .then((response) => response.json())
                                      .catch((e) => console.log(e));
                                  }
                                }}
                              >
                                <i className="ri-truck-line label-icon align-middle" style={{ backgroundColor: "#f6475f", color: "#fff" }}></i>
                                다음
                              </button>
                            </div>
                          </div>
                        </TabPane>

                        <TabPane tabId={2}>
                          <div>
                            <h5 className="mb-1" style={{ fontFamily: "Gmarket-M" }}>
                              상품정보
                            </h5>
                            <p className="mb-4" style={{ color: "#f6475f", fontWeight: 500 }}>
                              구매하실 상품을 확인해주세요.
                            </p>
                          </div>

                          <div className="mt-4">
                            <div className="d-flex align-items-center mb-2">
                              <div className="flex-grow-1">{/* <h5 className="fs-14 mb-0">상세정보</h5> */}</div>
                            </div>
                            <Row className="gy-3">
                              {location.state.products.map((product) => {
                                if (product.selectVariation)
                                  return (
                                    <Col key={product.id} lg={12} sm={6}>
                                      <div className="" style={{ padding: "20px", borderRadius: "5px", border: "1px solid #d1d1d1" }}>
                                        <div className="fw-normal text-wrap mb-1">
                                          <span className="mb-2 fw-semibold text-uppercase" style={{ fontFamily: "Gmarket-M" }}>
                                            {product.name}
                                          </span>

                                          {product.selectVariation.map((item) => {
                                            if (item.quantity)
                                              if (item.is_required)
                                                return (
                                                  <div
                                                    key={item.id}
                                                    className="fw-normal text-wrap mb-1 mx-1"
                                                    style={{ borderBottom: "1px solid #ededed", padding: "10px 0", marginTop: "10px", display: "flex", justifyContent: "space-between" }}
                                                  >
                                                    <span style={{ textAlign: "left" }}>
                                                      {item.value} ({item.price >= 0 ? "+" + item.price.toLocaleString() : item.price.toLocaleString()}원) X {item.quantity}개
                                                    </span>
                                                    <span style={{ fontFamily: "GMarket-M" }}>{((product.price + item.price) * item.quantity).toLocaleString()}원</span>
                                                  </div>
                                                );
                                            return (
                                              <div
                                                key={item.id}
                                                className="fw-normal text-wrap mb-1 mx-1"
                                                style={{ borderBottom: "1px solid #ededed", padding: "10px 0", marginTop: "10px", display: "flex", justifyContent: "space-between" }}
                                              >
                                                <span style={{ textAlign: "left" }}>
                                                  {item.value} ({item.price >= 0 ? "+" + item.price.toLocaleString() : item.price.toLocaleString()}원) X {item.quantity}개
                                                  <span style={{ fontFamily: "Gmarket-M", color: "#F64760" }}>&nbsp;추가상품</span>
                                                </span>
                                                <span style={{ fontFamily: "GMarket-M" }}>{(item.price * item.quantity).toLocaleString()}원</span>
                                              </div>
                                            );
                                          })}
                                        </div>
                                        <p style={{ textAlign: "right", marginTop: "20px" }}>
                                          <span className="w-normal text-wrap mb-1" style={{ fontFamily: "GMarket-M", color: "#F64760" }}>
                                            상품금액 &nbsp;
                                            {product.selectVariation
                                              .map((item) => {
                                                if (item.is_required) return (product.price + item.price) * item.quantity;
                                                return item.price * item.quantity;
                                              })
                                              .reduce((prev, curr) => prev + curr, 0)
                                              .toLocaleString()}
                                            원
                                          </span>
                                        </p>
                                      </div>
                                    </Col>
                                  );
                                if (!product.quantity) return null;
                                return (
                                  <Col key={product.id} lg={12} sm={6}>
                                    <div className="" style={{ padding: "20px", borderRadius: "5px", border: "1px solid #d1d1d1" }}>
                                      <div className="fw-normal text-wrap mb-1">
                                        <span className="mb-2 fw-semibold text-uppercase" style={{ fontFamily: "Gmarket-M" }}>
                                          {product.name}
                                        </span>

                                        <div
                                          className="fw-normal text-wrap mb-1 mx-1"
                                          style={{ borderBottom: "1px solid #ededed", padding: "10px 0", textAlign: "right", marginTop: "10px" }}
                                        >{`${new Intl.NumberFormat().format(product.price)}원 X ${product.quantity}개`}</div>
                                      </div>
                                      <p style={{ textAlign: "right", marginTop: "20px" }}>
                                        <span className="w-normal text-wrap mb-1" style={{ fontFamily: "GMarket-M", color: "#F64760" }}>{`상품금액 ${Number(
                                          product.price * product.quantity
                                        ).toLocaleString()}원`}</span>
                                      </p>
                                    </div>
                                  </Col>
                                );
                              })}
                            </Row>
                          </div>

                          <div className="d-flex align-items-start gap-3 mt-4">
                            <button
                              style={{ backgroundColor: "#f6475f", color: "#fff", fontFamily: "Gmarket-M" }}
                              type="button"
                              className="btn btn-label previestab"
                              onClick={() => {
                                toggleTab(activeTab - 1);
                              }}
                            >
                              <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2" style={{ backgroundColor: "#f6475f", color: "#fff" }}></i>
                              회원정보
                            </button>
                            <button
                              type="button"
                              className="btn btn-label right ms-auto nexttab"
                              style={{ backgroundColor: "#f6475f", color: "#fff", fontFamily: "Gmarket-M" }}
                              onClick={() => {
                                toggleTab(activeTab + 1);
                              }}
                            >
                              <i className="ri-bank-card-line label-icon align-middle fs-16 ms-2" style={{ backgroundColor: "#f6475f", color: "#fff" }}></i>
                              결제하기
                            </button>
                          </div>
                        </TabPane>

                        <TabPane tabId={3}>
                          <div>
                            <h5 className="mb-1" style={{ fontFamily: "Gmarket-M" }}>
                              결제하기
                            </h5>
                            <p className="mb-4" style={{ color: "#f6475f", fontWeight: 500 }}>
                              구매자 정보와 상품 정보를 확인해주세요.
                            </p>
                          </div>

                          <div className="collapse show" id="paymentmethodCollapse">
                            <Card className="p-4 border shadow-none mb-0 mt-4">
                              <Row className="gy-3">
                                <Col sm={6}>
                                  <div className="mb-3">
                                    <Label htmlFor="billinginfo-firstName" className="form-label">
                                      구매자
                                    </Label>
                                    <Input value={name} type="text" className="form-control" id="billinginfo-firstName" placeholder="구매자명" disabled />
                                  </div>
                                </Col>

                                <Col sm={6}>
                                  <div className="mb-3">
                                    <Label htmlFor="billinginfo-phone" className="form-label">
                                      휴대폰번호
                                    </Label>
                                    <Input value={user.phoneNum ?? ""} type="text" className="form-control" id="billinginfo-phone" placeholder="휴대폰번호" disabled />
                                  </div>
                                </Col>

                                <Col sm={12}>
                                  <div className="">
                                    <Label htmlFor="billinginfo-phone" className="form-label">
                                      배송지 주소
                                    </Label>

                                    <Input value={address + " " + addressDetail} type="text" className="form-control" id="billinginfo-firstName" placeholder="배송지" disabled />
                                  </div>
                                  <div className="mb-4 mt-3">
                                    {memo && (
                                      <>
                                        <Label htmlFor="billinginfo-phone" className="form-label">
                                          배송 메모
                                        </Label>
                                        <Input value={memo} type="text" className="form-control" id="billinginfo-firstName" placeholder="배송메모" disabled />
                                      </>
                                    )}
                                  </div>
                                </Col>
                              </Row>

                              <Row className="gy-3">
                                {location.state.products.map((product) => {
                                  if (product.selectVariation)
                                    return (
                                      <Col key={product.id} lg={12} sm={6}>
                                        <div className="" style={{ padding: "20px", borderRadius: "5px", border: "1px solid #d1d1d1" }}>
                                          <div className="fw-normal text-wrap mb-1">
                                            <span className="mb-2 fw-semibold text-uppercase" style={{ fontFamily: "Gmarket-M" }}>
                                              {product.name}
                                            </span>

                                            {product.selectVariation.map((item) => {
                                              if (item.quantity)
                                                if (item.is_required)
                                                  return (
                                                    <div
                                                      key={item.id}
                                                      className="fw-normal text-wrap mb-1 mx-1"
                                                      style={{ borderBottom: "1px solid #ededed", padding: "10px 0", marginTop: "10px", display: "flex", justifyContent: "space-between" }}
                                                    >
                                                      <span style={{ textAlign: "left" }}>
                                                        {item.value} ({item.price >= 0 ? "+" + item.price.toLocaleString() : item.price.toLocaleString()}원) X {item.quantity}개
                                                      </span>
                                                      <span style={{ fontFamily: "GMarket-M" }}>{((product.price + item.price) * item.quantity).toLocaleString()}원</span>
                                                    </div>
                                                  );
                                              return (
                                                <div
                                                  key={item.id}
                                                  className="fw-normal text-wrap mb-1 mx-1"
                                                  style={{ borderBottom: "1px solid #ededed", padding: "10px 0", marginTop: "10px", display: "flex", justifyContent: "space-between" }}
                                                >
                                                  <span style={{ textAlign: "left" }}>
                                                    {item.value} ({item.price >= 0 ? "+" + item.price.toLocaleString() : item.price.toLocaleString()}원) X {item.quantity}개
                                                    <span style={{ fontFamily: "Gmarket-M", color: "#F64760" }}>&nbsp;추가상품</span>
                                                  </span>
                                                  <span style={{ fontFamily: "GMarket-M" }}>{(item.price * item.quantity).toLocaleString()}원</span>
                                                </div>
                                              );
                                            })}
                                          </div>
                                          <p style={{ textAlign: "right", marginTop: "20px" }}>
                                            <span className="w-normal text-wrap mb-1" style={{ fontFamily: "GMarket-M", color: "#000" }}>
                                              배송비&nbsp;
                                              {product.free_delivery_condition > 0
                                                ? product.free_delivery_condition >
                                                  product.selectVariation
                                                    .map((item) => {
                                                      if (item.is_required) return (product.price + item.price) * item.quantity;
                                                      return item.price * item.quantity;
                                                    })
                                                    .reduce((prev, curr) => prev + curr, 0)
                                                  ? product.delivery_fee.toLocaleString()
                                                  : 0
                                                : product.delivery_fee.toLocaleString()}
                                              원
                                            </span>
                                          </p>
                                          <p style={{ textAlign: "right", marginTop: "20px" }}>
                                            <span className="w-normal text-wrap mb-1" style={{ fontFamily: "GMarket-M", color: "#F64760" }}>
                                              결제금액 &nbsp;
                                              {/* {product.selectVariation
                                                .map((item) => {
                                                  if (item.is_required) return (product.price + item.price) * item.quantity;
                                                  return item.price * item.quantity;
                                                })
                                                .reduce((prev, curr) => prev + curr, 0)
                                                .toLocaleString()} */}
                                              {product.free_delivery_condition > 0
                                                ? product.free_delivery_condition >
                                                  product.selectVariation
                                                    .map((item) => {
                                                      if (item.is_required) return (product.price + item.price) * item.quantity;
                                                      return item.price * item.quantity;
                                                    })
                                                    .reduce((prev, curr) => prev + curr, 0)
                                                  ? (
                                                      product.selectVariation
                                                        .map((item) => {
                                                          if (item.is_required) return (product.price + item.price) * item.quantity;
                                                          return item.price * item.quantity;
                                                        })
                                                        .reduce((prev, curr) => prev + curr, 0) + product.delivery_fee
                                                    ).toLocaleString()
                                                  : product.selectVariation
                                                      .map((item) => {
                                                        if (item.is_required) return (product.price + item.price) * item.quantity;
                                                        return item.price * item.quantity;
                                                      })
                                                      .reduce((prev, curr) => prev + curr, 0)
                                                      .toLocaleString()
                                                : (
                                                    product.selectVariation
                                                      .map((item) => {
                                                        if (item.is_required) return (product.price + item.price) * item.quantity;
                                                        return item.price * item.quantity;
                                                      })
                                                      .reduce((prev, curr) => prev + curr, 0) + product.delivery_fee
                                                  ).toLocaleString()}
                                              원
                                            </span>
                                          </p>
                                        </div>
                                      </Col>
                                    );
                                  if (!product.quantity) return null;
                                  return (
                                    <Col key={product.id} lg={12} sm={6}>
                                      <div className="" style={{ padding: "20px", borderRadius: "5px", border: "1px solid #d1d1d1" }}>
                                        <div className="fw-normal text-wrap mb-1">
                                          <span className="mb-2 fw-semibold text-uppercase" style={{ fontFamily: "Gmarket-M" }}>
                                            {product.name}
                                          </span>

                                          <div
                                            className="fw-normal text-wrap mb-1 mx-1"
                                            style={{ borderBottom: "1px solid #ededed", padding: "10px 0", textAlign: "right", marginTop: "10px" }}
                                          >{`${new Intl.NumberFormat().format(product.price)}원 X ${product.quantity}개`}</div>
                                          <p style={{ textAlign: "right", marginTop: "20px" }}>
                                            <span className="w-normal text-wrap mb-1" style={{ fontFamily: "GMarket-M", color: "#F64760" }}>{`결제금액 ${Number(
                                              product.price * product.quantity
                                            ).toLocaleString()}원`}</span>
                                          </p>
                                        </div>
                                      </div>
                                    </Col>
                                  );
                                })}
                              </Row>
                              {/* <p style={{ textAlign: "right", marginTop: "20px", marginBottom: "40px", paddingRight: "10px", fontWeight: "600" }}>
                                총 금액 &nbsp; <span className="w-normal text-wrap mb-1" style={{ fontFamily: "GMarket-M", color: "#F64760" }}>{` ${totalOrderPrice.toLocaleString()}원`}</span>
                              </p> */}
                            </Card>

                            <Card className="p-4 border shadow-none mb-0 mt-4">
                              <CardHeader className="border-0">
                                <Row className="g-4 align-items-center">
                                  <div className="col-sm">
                                    <div>
                                      <h6 className="mb-3 ff-secondary fw-semibold lh-base">취소/환불 규정에 대한 동의</h6>
                                    </div>
                                    <div className="text-muted">
                                      <span>배송 관련 질문은 문의게시판 혹은 카카오톡 채널로 문의주시기 바랍니다.</span>
                                      <br />
                                      <span>불량 및 교환 문의는 반드시 고객센터를 통해서 문의주세요. </span>
                                      <p>울산점 0507-1423-2223</p>
                                    </div>
                                  </div>
                                </Row>
                              </CardHeader>
                              <Row className="gy-3">
                                <Col sm={6}>
                                  <label className="form-check-label" htmlFor="-"></label>
                                </Col>
                              </Row>
                              <br />
                              <hr />
                              <Row className="gy-3">
                                <Col sm={12}>
                                  <div className="form-check">
                                    <Input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="cancelRuleAgree"
                                      checked={cancelRuleAgree}
                                      onChange={(e) => {
                                        setCancelRuleAgree(e.target.checked);
                                      }}
                                    />
                                    <label className="form-check-label" htmlFor="cancelRuleAgree">
                                      동의하기
                                    </label>
                                  </div>
                                </Col>
                              </Row>
                            </Card>

                            <Card className="p-4 border shadow-none mb-0 mt-4">
                              <CardHeader className="border-0">
                                <Row className="g-4 align-items-center">
                                  <div className="col-sm">
                                    <div>
                                      <h6 className="mb-3 ff-secondary fw-semibold lh-base">결제동의</h6>
                                    </div>
                                  </div>
                                </Row>
                              </CardHeader>
                              <Row className="gy-3">
                                <Col sm={12}>
                                  <div className="form-check">
                                    <Input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="purchaseAllAgree"
                                      checked={purchaseAllAgree}
                                      onChange={(e) => {
                                        toggleAllAgree(e.target.checked);
                                      }}
                                    />
                                    <label className="form-check-label" htmlFor="purchaseAllAgree">
                                      전체 동의하기
                                    </label>
                                  </div>
                                </Col>
                              </Row>
                              <br />
                              <hr />
                              <Row className="gy-3">
                                <Col sm={12}>
                                  <div className="form-check">
                                    <Input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="purchaseOneAgree"
                                      checked={purchaseOneAgree}
                                      onChange={(e) => {
                                        togglePurchaseAgreeOptions(e.target.checked);
                                      }}
                                    />
                                    <label className="form-check-label" htmlFor="purchaseOneAgree">
                                      (필수)구매조건 확인 및 결제진행에 동의
                                    </label>
                                  </div>
                                </Col>
                              </Row>
                            </Card>
                          </div>

                          <div className="d-flex align-items-start gap-3 mt-4">
                            <button
                              type="button"
                              style={{ backgroundColor: "#f6475f", color: "#fff", fontFamily: "Gmarket-M" }}
                              className="btn btn-label previestab"
                              onClick={() => {
                                toggleTab(activeTab - 1);
                              }}
                            >
                              <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2 " style={{ backgroundColor: "#f6475f", color: "#fff" }}></i>
                              상품정보
                            </button>
                            <button
                              type="button"
                              style={{ backgroundColor: "#f6475f", color: "#fff", fontFamily: "Gmarket-M" }}
                              className="btn right ms-auto nexttab"
                              onClick={() => {
                                if (location.state.products.find((item) => item.selectVariation)) {
                                  variationProcessPayment();
                                } else {
                                  processPayment();
                                }
                              }}
                              disabled={!cancelRuleAgree || !purchaseAllAgree}
                            >
                              결제하기
                              <i className="ri-shopping-basket-line label-icon align-middle fs-16 ms-2" style={{ backgroundColor: "#f6475f", color: "#fff", marginLeft: "10px" }}></i>
                            </button>
                          </div>
                        </TabPane>
                      </TabContent>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    </BgFrame>
  );
};

export default ShoppingOrderProcess;
