import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  ListGroup,
  ListGroupItem,
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Row,
  TabContent,
  Table,
  TabPane,
  UncontrolledCollapse,
  UncontrolledDropdown,
} from "reactstrap";
import classnames from "classnames";
import { Swiper, SwiperSlide } from "swiper/react";
import Navbar from "../../Components/Common/navbar";
import SwiperCore, { Autoplay } from "swiper";
import styled from "styled-components";
import Coupon from "./purchaseList/Coupon";
import UserVoucher from "./purchaseList/UserVoucher";
import PurchaseRecord from "./purchaseList/PurchaseRecored";

import CancelList from "./purchaseList/CancelList";
import jwtDecode from "jwt-decode";
import { useLocation } from "react-router-dom";
import { getAccessToken, removeAccessToken } from "../Authentication/auth";
import ReservationGift from "./purchaseList/ReservationGift";
import UserVoucherGift from "./purchaseList/VoucherGift";
import { QRCodeSVG } from "qrcode.react";
import GiftTabView from "./purchaseList/GiftTabView";
import { BACKEND_HOST } from "../../api";
import ReservationList from "./purchaseList/ReservationList";
import { SITE_ID } from "../../assets/meta/meta";
import ShoppingPurchaseList from "./purchaseList/ShoppingPurchaseList";
import { IoArrowBackOutline } from "react-icons/io5";

const ProfileContent = styled.div`
  background-color: yellowgreen;
  /* background-image: url('https://cdn.imweb.me/thumbnail/20220623/f7fd1b68c5e92.jpg');
    background-size:100% 100%; */
`;

const Line1 = styled.div`
  background-color: green;
`;

const Btn = styled.div`
  :hover {
    transform: scale(1.2);
    transition: 0.3s;
  }
`;

const BtnImg = styled.img``;
export default function MyPage({ tabparam = "index" }) {
  SwiperCore.use([Autoplay]);

  // const [displayCategory, setCategory] = useState("All");
  // const [voucherCount, setVoucherCount] = useState(0);
  const [reservGiftCount, setReservGiftCount] = useState(0);
  const [ticketGiftCount, setTicketGiftCount] = useState(0);
  const [userVoucherGiftCount, setUserVoucherGiftCount] = useState(0);

  /* 임시 이미지 */
  const [thumbnailImg, setThumbnailImg] = useState(`${BACKEND_HOST}/media/ProfileBlank.png`);
  const [activeTab, setActiveTab] = useState("0");
  const [myQRcode, setMyQRcode] = useState("");

  const toggleTab = useCallback(
    (tab) => {
      if (activeTab !== tab) {
        setActiveTab(tab);
      }
    },
    [activeTab]
  );

  const navigate = useNavigate();
  const location = useLocation();
  React.useEffect(() => {
    // console.log("location", location)
    const tab = location.state?.tab;
    if (tab) {
      // console.log("location", tab)
      setActiveTab(tab);
    }
  }, [location]);

  const [name, setName] = React.useState("");

  // React.useEffect(() => {
  //     if (tabparam === "gift-box") {
  //         setActiveTab('5');
  //     }
  // }, [tabparam])

  const [kakaoChannelsId, setkakaoChannelsId] = React.useState("");
  React.useEffect(() => {
    fetch(`${BACKEND_HOST}/sites/kakao-channels/${SITE_ID}`)
      .then((response) => response.json())
      .then((data) => {
        setkakaoChannelsId(data.kakaoChannelsId);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  useEffect(() => {
    // get code from url parameters
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get("code");
    const message = urlParams.get("message");
    if (code) {
      // console.log("code", code);
      // console.log("message", message);
      alert(`결제가 실패하였습니다. ${code}`, message);

      // remove code from url
      window.history.replaceState({}, document.title, "/my-page");
    }
  }, []);

  React.useEffect(() => {
    const accessToken = getAccessToken();
    let qrcode = "";
    let thumbnailUrl = "";
    if (accessToken) {
      let decoded = {};
      try {
        decoded = jwtDecode(accessToken);
      } catch (e) {
        console.log(e);
      }

      const userId = decoded["user_id"];
      fetch(`${BACKEND_HOST}/accounts/users/${userId}/`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          const phoneNum = data["전화번호"];

          // UserVoucherGift Count
          fetch(`${BACKEND_HOST}/voucher/user-voucher-gift/?recipient=${phoneNum}`, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          })
            .then((response) => response.json())
            .then((data) => {
              const _gifts = data.results.filter((gift) => gift.voucher_state === "사용안함");
              setUserVoucherGiftCount(_gifts.length);
            })
            .catch((e) => {
              console.log(e);
            });

          // Reservation Gift Count
          fetch(`${BACKEND_HOST}/package/reservation-gift/?recipient=${phoneNum}`, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          })
            .then((response) => response.json())
            .then((data) => {
              const _gifts = data.results.filter((gift) => gift.reserv_state === "예약");
              setReservGiftCount(_gifts.length);
            })
            .catch((e) => {
              console.log(e);
            });

          // Ticket Gift Count
          fetch(`${BACKEND_HOST}/ticket/ticket-gift/?recipient=${phoneNum}`, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          })
            .then((response) => response.json())
            .then((data) => {
              const _gifts = data.results.filter((gift) => gift.ticket_state === "사용안함");
              setTicketGiftCount(_gifts.length);
            })
            .catch((e) => {
              console.log(e);
            });

          setName(data["이름"]);

          fetch(`${BACKEND_HOST}/accounts/user-qr/`, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          })
            .then((response) => response.json())
            .then((resdata) => (qrcode = resdata["qrcode"]))
            .then((qrdata) => setMyQRcode(qrcode))
            .catch((e) => console.log(e));

          fetch(`${BACKEND_HOST}/accounts/profile-img/`, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          })
            .then((response) => response.json())
            .then((resdata) => {
              thumbnailUrl = resdata["profile_img"];
              // console.log("thumbnailUrl", thumbnailUrl, resdata);
              if (thumbnailUrl) {
                setThumbnailImg(thumbnailUrl);
              }
            })
            .catch((e) => console.log(e));
        })
        .catch((e) => console.log(e));
    }
  }, []);
  return (
    <React.Fragment>
      <div className="layout-wrapper landing" style={{ backgroundColor: "#fff9fa" }}>
        <div style={{ maxWidth: "640px", margin: "0 auto", backgroundColor: "#fff" }}>
          <Navbar />
          <Container fluid style={{ minHeight: "100vh", backgroundColor: "white" }}>
            {/* <div className="profile-foreground position-relative mx-n4 mt-n4">
                        <div className="profile-wid-bg"></div>
                    </div> */}
            {activeTab === "0" ? (
              <div className="pt-4 mb-4 mb-lg-3 pb-lg-4 mt-3">
                <div className=" d-flex flex-column align-items-center justify-content-between flex-row">
                  <div className="col-auto">
                    <div className="d-flex justify-content-center" style={{ flexDirection: "column", alignItems: "center" }}>
                      {/* <img className="fluid" src={thumbnailImg} style={{ objectFit: "cover", width: "92px", height: "92px", borderRadius: "42%" }} alt="profile image" /> */}
                      <QRCodeSVG value={myQRcode} size="86px" />
                      <h3 className="text-dark mt-3 mb-1" style={{ textAlign: "center", fontFamily: "Gmarket-M", fontSize: "18px" }}>
                        {name}님 안녕하세요!
                      </h3>
                    </div>
                  </div>
                  <div style={{ marginBottom: "12px" }}></div>
                  {/* <div className="col-auto pr-4">
                    <QRCodeSVG value={myQRcode} size="86px" />
                  </div> */}
                </div>
              </div>
            ) : null}

            <div style={{ marginBottom: "18px" }}></div>

            {/* {activeTab === "0" ? <ReservationList navigate={navigate} todayOnly /> : null} */}

            <Row>
              <Col lg={12}>
                <div>
                  {activeTab === "0" ? (
                    <div>
                      <ListGroup flush>
                        <ListGroupItem
                          tag="a"
                          href="#"
                          style={{ fontSize: "16px" }}
                          onClick={() => {
                            toggleTab("1");
                          }}
                          className="d-flex justify-content-between"
                        >
                          <span>구매내역</span>
                          <i className="ri-arrow-right-s-line"></i>
                        </ListGroupItem>
                        <ListGroupItem
                          tag="a"
                          href="#"
                          style={{ fontSize: "16px" }}
                          onClick={() => {
                            toggleTab("3");
                          }}
                          className="d-flex justify-content-between"
                        >
                          <span>교환권</span>
                          <i className="ri-arrow-right-s-line"></i>
                        </ListGroupItem>
                        <ListGroupItem
                          tag="a"
                          href="#"
                          style={{ fontSize: "16px" }}
                          onClick={() => {
                            toggleTab("5");
                          }}
                          className="d-flex justify-content-between"
                        >
                          <span>
                            선물함 {userVoucherGiftCount || reservGiftCount || ticketGiftCount ? <Badge color="danger">{userVoucherGiftCount + reservGiftCount + ticketGiftCount}</Badge> : null}
                          </span>
                          <i className="ri-arrow-right-s-line"></i>
                        </ListGroupItem>

                        <ListGroupItem
                          tag="a"
                          href="#"
                          style={{ fontSize: "16px" }}
                          onClick={() => {
                            toggleTab("7");
                          }}
                          className="d-flex justify-content-between"
                        >
                          <span>쇼핑몰 주문내역</span>
                          <i className="ri-arrow-right-s-line"></i>
                        </ListGroupItem>

                        <ListGroupItem
                          tag="a"
                          href="#"
                          style={{ fontSize: "16px" }}
                          onClick={() => {
                            toggleTab("2");
                          }}
                          className="d-flex justify-content-between"
                        >
                          <span>취소내역</span>
                          <i className="ri-arrow-right-s-line"></i>
                        </ListGroupItem>
                        {/* <ListGroupItem
                          tag="a"
                          href="#"
                          style={{ fontSize: "16px" }}
                          onClick={() => {
                            toggleTab("4");
                          }}
                          className="d-flex justify-content-between"
                        >
                          <span>정보수정</span>
                          <i className="ri-arrow-right-s-line"></i>
                        </ListGroupItem> */}
                        {/* <ListGroupItem tag="a" href="#" style={{ fontSize: "16px" }} onClick={() => { toggleTab('6'); }} className="d-flex justify-content-between"><span>Privacy & Security</span><i className="ri-arrow-right-s-line"></i></ListGroupItem> */}
                        {/* <ListGroupItem tag="a" href="#" style={{ fontSize: "16px" }} onClick={() => {
                                                window.open(`https://pf.kakao.com/${kakaoChannelsId}`, "_blank")
                                            }} className="d-flex justify-content-between"><span>1:1문의</span><i className="ri-arrow-right-s-line"></i></ListGroupItem> */}
                        <ListGroupItem
                          tag="a"
                          href="#"
                          style={{ fontSize: "16px" }}
                          onClick={() => {
                            navigate("/inquiry/form/0");
                          }}
                          className="d-flex justify-content-between"
                        >
                          <span>문의하기</span>
                          <i className="ri-arrow-right-s-line"></i>
                        </ListGroupItem>

                        <ListGroupItem
                          tag="a"
                          href="#"
                          style={{ fontSize: "16px" }}
                          onClick={() => {
                            localStorage.removeItem("refresh_token");
                            removeAccessToken();
                            if (location.pathname === "/") navigate(0);
                            else navigate("/");
                          }}
                          className="d-flex justify-content-between"
                        >
                          <span>로그아웃</span>
                          <i className="ri-arrow-right-s-line"></i>
                        </ListGroupItem>
                      </ListGroup>

                      {/* <div className="mt-5">
                        <Button
                          tag="a"
                          href="#"
                          size="md"
                          color="light"
                          style={{ boxShadow: "none" }}
                          onClick={() => {
                            localStorage.removeItem("refresh_token");
                            removeAccessToken();
                            if (location.pathname === "/") navigate(0);
                            else navigate("/");
                          }}
                        >
                          <i className="ri-logout-circle-r-line"></i>
                          <span> 로그아웃</span>
                        </Button>
                      </div> */}
                    </div>
                  ) : (
                    <div style={{ backgroundColor: "#fff", padding: "10px 0" }} className="sticky-top">
                      <Button
                        color="white"
                        className="add-btn"
                        style={{ backgroundColor: "transparent", boxShadow: "none", border: "none" }}
                        onClick={() => {
                          toggleTab("0");
                        }}
                        id="edit-btn"
                      >
                        <IoArrowBackOutline size={26} />
                      </Button>
                      {/* <h4
                        style={{
                          fontSize: "16px",
                          fontFamily: "Gmarket-M",
                          position: "absolute",
                          margin: "0 auto",
                          marginBottom: "0",
                          textAlign: "center",
                          left: "49%",
                          top: "50%",
                          transform: "translate(-50%,-50%)",
                        }}
                      >
                        마이페이지
                      </h4> */}
                    </div>
                    // <div>
                    //   <Button
                    //     tag="a"
                    //     href="#"
                    //     color="ghost"
                    //     style={{ boxShadow: "none" }}
                    //     onClick={() => {
                    //       toggleTab("0");
                    //     }}
                    //   >
                    //     <i className="ri-arrow-left-line"></i>
                    //     <span> 돌아가기</span>
                    //   </Button>
                    // </div>
                  )}

                  <TabContent activeTab={activeTab} hidden={activeTab === "0"}>
                    <TabPane tabId="1">
                      <PurchaseRecord />
                    </TabPane>
                    <TabPane tabId="2">
                      <Card>
                        <CardBody>
                          <CancelList />
                        </CardBody>
                      </Card>
                    </TabPane>

                    <TabPane tabId="3">
                      <Card>
                        <CardBody>
                          <UserVoucher />
                        </CardBody>
                      </Card>
                    </TabPane>

                    <TabPane tabId="4">
                      <Card>
                        <CardBody>
                          <div>정보수정 기능 추가 예정입니다.</div>
                        </CardBody>
                      </Card>
                    </TabPane>

                    <TabPane tabId="5">
                      <Card>
                        <CardBody>
                          <GiftTabView />
                        </CardBody>
                      </Card>
                    </TabPane>

                    <TabPane tabId="6">
                      <Card>
                        <CardBody>
                          <div>Privacy and Security</div>
                          <div>Will be updated soon.</div>
                        </CardBody>
                      </Card>
                    </TabPane>

                    <TabPane tabId="7">
                      <Card>
                        <CardBody>
                          <ShoppingPurchaseList />
                        </CardBody>
                      </Card>
                    </TabPane>
                  </TabContent>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
}
