import React, { useState, useEffect, useMemo } from "react";
import {
  Collapse,
  Container,
  Nav,
  NavItem,
  NavbarToggler,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from "reactstrap";
import Scrollspy from "react-scrollspy";
import { Link, useNavigate, useLocation } from "react-router-dom";
import jwtDecode from "jwt-decode";
import UnderNavbar from "./underNavbar";
import Profile from "./Profile";
import { SITE_ID } from "../../assets/meta/meta";
import { fetchErrorHandling, getAccessToken, removeAccessToken } from "../../pages/Authentication/auth";
import { BACKEND_HOST } from "../../api";
import { useUserStore } from "../../store/store";
import AnnounceModal from "./NoticeModal";

import { isMobile, isAndroid, isIOS } from "react-device-detect";
import moment from "moment";
import axios from "axios";
import { BsPerson } from "react-icons/bs";

const Navbar = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [userInfo, setUserInfo] = useState({
    phone_num: null,
  });

  const [user, setUser, permission] = useUserStore((state) => [state.user, state.setUser, state.permission]);
  const [isWebPurchase, isWebPurchaseTicket, isWebShopping] = useUserStore((state) => [state.isWebPurchase, state.isWebPurchaseTicket, state.isWebShopping]);

  const [siteName, setSiteName] = useState("");
  const [isOpenMenu, setisOpenMenu] = useState(false);
  const [profileImo, setProfileImo] = useState("ri-user-fill");

  const [navClass, setnavClass] = useState("");
  const [activeNavBar, setActiveNavBar] = useState(props.activeBar);

  const navTabName = useMemo(() => {
    return [
      { name: "홈", url: "/", canAccess: true, visible: true },
      { name: "예약", url: "/reservation", canAccess: true, visible: isWebPurchase },
      { name: "티켓", url: "/ticket", canAccess: true, visible: isWebPurchaseTicket },
      { name: "쇼핑", url: "/shop", canAccess: true, visible: isWebShopping },
      // { name: "위치안내", url: "/camp-map", canAccess: true, visible: true },
      { name: "문의", url: "/inquiry", canAccess: true, visible: true },
    ];
  }, [isWebPurchase, isWebPurchaseTicket, isWebShopping]);

  const toggle = () => setisOpenMenu(!isOpenMenu);

  useEffect(() => {
    window.addEventListener("scroll", scrollNavigation, true);
  }, []);

  useEffect(() => {
    fetch(`${BACKEND_HOST}/sites/camps/${SITE_ID}/`)
      .then((response) => response.json())
      .then((data) => {
        setSiteName(data.name);
      })
      .catch((e) => console.log(e));
  }, []);

  useEffect(() => {
    const accessToken = getAccessToken();
    if (accessToken && !userInfo.phone_num) {
      let decoded = {};
      try {
        decoded = jwtDecode(accessToken);
      } catch (e) {
        logOut();
        navigate(`/login`);
        // alert("got error decode", accessToken)
      }

      const userId = decoded["user_id"];
      // alert("user id: " + userId)

      fetch(
        // `${BACKEND_HOST}/accounts/users/${userId}/`, {
        `${BACKEND_HOST}/accounts/users/${userId}/?path=${btoa(location.pathname)}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
        // .then((response) => response.json())
        .then((response) => fetchErrorHandling(response))
        .then((data) => {
          // console.log("user login verify:", data);
          // alert(data)
          if (!data["전화번호"]) {
            logOut();
          } else {
            setUserInfo({
              phone_num: data["전화번호"],
            });
          }
        })
        .catch((e) => {
          console.log(e);
          navigate(`/`);
        });

      fetch(`${BACKEND_HOST}/accounts/user-info/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then(async (response) => {
          if (response.ok) {
            return response.json();
          } else {
            const message = response.body || response.status + " An error occurred while fetching the data.";
            throw new Error(message);
          }
        })
        .then((results) => {
          console.log(results);
          setUser(results);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setUserInfo({
        phone_num: null,
      });
    }

    console.log("KakaoChannelCheck: Checking KakaoTalk channel status " + (accessToken ? "token_true " : "token_null ") + user?.kakaoChannelLinked);
    if (accessToken && !user?.kakaoChannelLinked) {
      checkKakaoLoginStatus(false);
    }
  }, []);

  const logOut = (redirect_path) => {
    setUserInfo({
      phone_num: null,
    });

    localStorage.removeItem("refresh_token");
    removeAccessToken();

    if (redirect_path) navigate(redirect_path);
    else if (location.pathname === "/") navigate(0);
    else navigate("/");
    // alert("try to logout")
  };

  function scrollNavigation() {
    var scrollup = document.documentElement.scrollTop;
    if (scrollup > 50) {
      setnavClass("is-sticky");
    } else {
      setnavClass("");
    }
  }

  const [showQuickInquiry, setShowQuickInquiry] = useState(false);

  useEffect(() => {
    // console.log("location.pathname", location.pathname)
    if (!["", "/", "/my-page", "/camp-map", "/inquiry"].includes(location.pathname)) {
      setShowQuickInquiry(false);
      return;
    }
    setShowQuickInquiry(true);
  }, [location]);

  // ======================================================================================================
  //    Modals settings
  // ======================================================================================================

  const MODAL_STACK = {
    RAIN_CANCEL: "RAIN_CANCEL",
    ANNOUNCE: "ANNOUNCE",
    VOUCHER: "VOUCHER",
    DEPOSIT: "DEPOSIT",
    KAKAO_CHANNEL_AGREE: "KAKAO_CHANNEL_AGREE",
  };

  const [modalStack, setModalStack] = useState([]);
  const stackModal = (modal) => {
    setModalStack((prev) => {
      const _prev = [...prev];
      if (_prev.indexOf(modal) === -1) {
        _prev.push(modal);
      }
      return _prev;
    });
  };
  const closeModal = (modal) => {
    const _modalStack = [...modalStack];
    const idx = _modalStack.indexOf(modal);
    if (idx !== -1) {
      _modalStack.splice(idx, 1);
    }
    setModalStack(_modalStack);
  };
  const isModalOpened = (modal) => {
    if (!["", "/"].includes(location.pathname)) {
      return false;
    }
    return modalStack.length && modalStack[0] === modal;
  };

  // const [isPushedVoucher, setIsPushedVoucher] = useState(false);
  // const [announceModalVisible, setAnnounceModalVisible] = useState(false);
  // const [depositModal, setDepositModal] = useState(false);
  // const [cancelableModal, setCancelableModal] = useState(false);
  // const toggleModal = () => setCancelableModal(!cancelableModal);

  const [cancelChecked, setCancelChecked] = useState(false);

  const [announceDatas, setAnnounceDatas] = useState([]);

  const [isMo, setIsMobile] = useState(false);
  const [isAn, setIsAndroid] = useState(false);
  const [isIos, setIsIOS] = useState(false);

  const [todaysReservations, setTodaysReservations] = useState([]);
  const [depositReservId, setDepositReservId] = useState(null);

  const changeCancelableUserConfirm = (isConfirm) => {
    const accessToken = getAccessToken();
    if (accessToken) {
      fetch(`${BACKEND_HOST}/sites/cancelable-user-confirm/`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          confirm: isConfirm,
          read: true,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.result === "success") {
            console.log("confirm success");
            closeModal(MODAL_STACK.RAIN_CANCEL);
          }
        });
    }
  };
  const changeCancelableUserRead = () => {
    const accessToken = getAccessToken();
    if (accessToken) {
      fetch(`${BACKEND_HOST}/sites/cancelable-user-confirm/`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          confirm: false,
          read: true,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.result === "success") {
            console.log("read success");
          }
        });
    }
  };

  const checkKakaoLoginStatus = async (silent = false) => {
    try {
      const response = await axios.get(`${BACKEND_HOST}/api/kakao/channels/`, {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      });
      const data = response.data;
      // console.log('KakaoChannelCheck: KakaoTalk channel check result:', data);
      if (data.result) {
        console.log("KakaoChannelCheck: User is subscribed to KakaoTalk channel", data);
        // userUpdate({ kakaoChannelLinked: data.result });
        setUser({ ...user, kakaoChannelLinked: data.result });
        return true;
      } else {
        console.log("KakaoChannelCheck: User is not subscribed to KakaoTalk channel", data);
        if (!silent) stackModal(MODAL_STACK.KAKAO_CHANNEL_AGREE);
      }
    } catch (error) {
      console.log("KakaoChannelCheck: Error checking Kakao login status:", error);
      console.log(error.response?.data);
    }
    return false;
  };

  useEffect(() => {
    const accessToken = getAccessToken();
    if (accessToken && !userInfo.phone_num) {
      fetch(`${BACKEND_HOST}/sites/cancelable-user-find/`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("cancelable user find:", data);
          // {
          //     "result": {
          //       "cancel_day": "2023-05-03",
          //       "phone_num": "01073032625",
          //       "is_confirmed": false,
          //       "is_read": true
          //     }
          //   }
          if (data.result && data.result.is_confirmed === false) {
            // setCancelableModal(true);
            stackModal(MODAL_STACK.RAIN_CANCEL);
            changeCancelableUserRead();
          }
        })
        .catch((e) => console.log("cancelable error", e));
    }
  }, [userInfo]);

  useEffect(() => {
    fetch(`${BACKEND_HOST}/notice/announce/`)
      .then((response) => response.json())
      .then((data) => {
        if (data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            const element = data[i];
            let _link = element.link;
            if (isMobile) {
              if (isAndroid) {
                _link = element.android_link;
              } else if (isIOS) {
                _link = element.ios_link;
              }
            }
            // console.log("announce data", data)

            const accessToken = getAccessToken();
            if (!accessToken) {
              setAnnounceDatas((prev) => [...prev, { ...element, link: _link }]);
              stackModal(MODAL_STACK.ANNOUNCE + element.id);
            } else {
              fetch(`${BACKEND_HOST}/notice/announce-read/?id=${element.id}`, {
                method: "GET",
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                },
              })
                .then((response) => response.json())
                .then((_data) => {
                  // console.log("announce read", _data)
                  if (_data.length === 0) {
                    setAnnounceDatas((prev) => [...prev, { ...element, link: _link }]);
                    stackModal(MODAL_STACK.ANNOUNCE + element.id);
                  }
                })
                .catch((e) => console.log(e));
            }
          }
        }
      })
      .catch((e) => console.log(e));

    const accessToken = getAccessToken();
    if (accessToken) {
      fetch(`${BACKEND_HOST}/voucher/unchecked-count/`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data["unchecked_count"] > 0) stackModal(MODAL_STACK.VOUCHER);
        })
        .catch((e) => console.log(e));
    }

    setIsMobile(isMobile);
    setIsAndroid(isAndroid);
    setIsIOS(isIOS);
  }, []);

  useEffect(() => {
    const accessToken = getAccessToken();
    if (!accessToken || !user) {
      return;
    }
    const today = moment().format("YYYY-MM-DD");
    setTodaysReservations([]);

    fetch(`${BACKEND_HOST}/package/user-order/?filter_date=${today}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("user-order", data);
        if (data.orders.length > 0) {
          const _reservs = data.orders.map((order) => {
            return {
              ...order,
              reserv_id: order.id,
            };
          });
          setTodaysReservations((prev) => [...prev, ..._reservs]);
        }
      })
      .catch((e) => console.log(e));

    fetch(`${BACKEND_HOST}/package/reservation-gift/?recipient=${user.phone}&date=${today}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("reservation-gift", data);
        if (data.results.length > 0) {
          setTodaysReservations((prev) => [...prev, ...data.results]);
        }
      })
      .catch((e) => console.log(e));
  }, [user]);

  useEffect(() => {
    closeModal(MODAL_STACK.DEPOSIT);

    for (let i = 0; i < todaysReservations.length; i++) {
      if (todaysReservations[i].gifted) {
        continue;
      }
      if (todaysReservations[i].reserv_state && todaysReservations[i].reserv_state !== "예약") {
        continue;
      }
      if (todaysReservations[i].state && todaysReservations[i].state !== "예약") {
        continue;
      }

      if (todaysReservations[i].depositRequired) {
        setDepositReservId(todaysReservations[i].reserv_id);
        stackModal(MODAL_STACK.DEPOSIT);
        break;
      }
    }
  }, [todaysReservations]);

  return (
    <React.Fragment>
      <div className="sticky-top bg-white border-bottom py-1 px-2">
        <nav className={"navbar navbar-expand-lg navbar " + navClass} id="navbar">
          <Container
            className="d-flex justify-content-between"
            // style={{ width: "calc(100% - 24px)"}}
          >
            {/* <NavbarToggler
              className="navbar-toggler py-0 fs-2 border-0 text-body"
              onClick={toggle}
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <i className="mdi mdi-menu"></i>
            </NavbarToggler> */}
            <div className="d-flex justify-content-center">
              <Link className="navbar-brand nav-bar-color" to="/" style={{ fontFamily: "Jalnan" }}>
                {siteName}
              </Link>
            </div>
            {/* <div className="bg-white"></div> */}
            <Collapse isOpen={isOpenMenu} className="navbar-collapse" id="navbarSupportedContent">
              <div className="navbar-nav mx-auto mt-2 mt-lg-0" id="navbar-example">
                {/* {navTabName.map((tab, idx) => {
                  return (
                    <div key={idx} className="ms-4" style={tab.visible ? {} : { display: "none" }}>
                      {activeNavBar === idx ? (
                        // <div className="nav-item" >
                        //     {tab.url !== "#" ?
                        //         Link className="fs-15 border-bottom border-danger" href={tab.url} style={{ cursor: "pointer" }}>{tab.name}</NavLink>
                        //         : <NavLink className="fs-15 border-bottom border-danger" onClick={() => { navigate('/login'); }}>{tab.name}</NavLink>}
                        // </div>
                        <div className="nav-item">
                          <Link to={tab.url} className="nav-item text-dark fs-15 border-bottom border-danger" style={{ cursor: "pointer", fontFamily: "Gmarket-M" }}>
                            {tab.name}
                          </Link>
                        </div>
                      ) : (
                        // <div className="nav-item">
                        //     {tab.url !== "#" ?
                        //         <NavLink className="fs-15 border-danger" href={tab.url} style={{ cursor: "pointer" }}>{tab.name}</NavLink>
                        //         : <NavLink className="fs-15 border-danger" onClick={() => { navigate('/login'); }}>{tab.name}</NavLink>}
                        // </div>
                        <div className="nav-item">
                          <Link to={tab.url} className="nav-item text-dark fs-15 border-danger" style={{ cursor: "pointer", fontFamily: "Gmarket-M" }}>
                            {tab.name}
                          </Link>
                        </div>
                      )}
                    </div>
                  );
                })} */}
              </div>
              <div className="d-flex flex-nowrap">
                {userInfo["phone_num"] === null ? (
                  <React.Fragment>
                    {/* <Link to={`/login`} className="btn btn-link fw-medium text-decoration-none text-dark"> */}
                    <Link to={`/login`} className="" style={{ color: "#404040", lineHeight: "40px", fontFamily: "Gmarket-M", marginLeft: "100px" }}>
                      <BsPerson size="25" />
                    </Link>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <div style={{}}>
                      <Link to="/my-page" className="" style={{ color: "#404040", lineHeight: "40px", fontFamily: "Gmarket-M" }}>
                        <BsPerson size="25" />
                      </Link>
                    </div>
                    {/* <div style={{ marginLeft: 15 }}>
                      <Link to="/" onClick={logOut} style={{ color: "#404040", lineHeight: "40px", fontFamily: "Gmarket-M" }}>
                        로그아웃
                      </Link>
                    </div> */}
                  </React.Fragment>
                )}
              </div>
            </Collapse>

            <div className="navbar-nav d-block d-lg-none d-xl-none">
              {userInfo["phone_num"] === null ? (
                <Link to={`/login`} className="ms-3" style={{ cursor: "pointer" }}>
                  <BsPerson size="25" />
                </Link>
              ) : (
                <Link to="/my-page" className="" style={{ color: "#404040", lineHeight: "40px", fontFamily: "Gmarket-M" }}>
                  <BsPerson size="25" />
                </Link>
                // <UncontrolledDropdown>
                //   <DropdownToggle
                //     id="dropdownMenuButton"
                //     className="bg-white fs-20 border-0 d-flex flex-column justify-content-center"
                //     style={{ boxShadow: "none", color: "black", borderRadius: "50%", width: "28px", height: "28px", zIndex: 2 }}
                //   >
                //     <div className="d-flex w-100 justify-content-center" style={{ zIndex: "1" }}>
                //       <i className={profileImo} />
                //     </div>
                //   </DropdownToggle>
                //   <DropdownMenu style={{ cursor: "default" }}>
                //     <Profile logOut={logOut} profileImo={profileImo} />
                //   </DropdownMenu>
                // </UncontrolledDropdown>
              )}
            </div>
          </Container>

          {/* 화면축소시 프로필 - 이미지는 fetch get으로 호출해야함 */}
          {/* <NavbarToggler className="navbar-toggler position-absolute" data-bs-toggle="collapse"
                        style={{ top: "13px", right: "2%", border: "none" }}
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation"
                    > */}
          {/* </NavbarToggler> */}
        </nav>
      </div>

      {/* <UnderNavbar activeBar={props.activeBar} navTabName={navTabName} /> */}

      <div id="kakao-talk-channel-chat-button" style={{ position: "fixed", right: "14px", bottom: "48px", zIndex: 999999 }}></div>
      {/* {showQuickInquiry ? (
        <div style={{ position: "fixed", right: "14px", bottom: "48px", zIndex: 6 }}>
          <Link to="/inquiry/form/0" style={{ width: "86px", height: "42px" }}>
            <div
              style={{
                width: "86px",
                height: "42px",
                borderRadius: "30px",
                backgroundColor: "white",
                boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <i className="ri-chat-1-fill ri-xl text-primary"></i>
              <div style={{ marginLeft: "8px", fontSize: "15px", fontWeight: "bold" }}>문의</div>
            </div>
          </Link>
        </div>
      ) : null} */}

      {/* 
            // ======================================================================================================
            //    Modals
            // ====================================================================================================== 
            */}

      {/* 우천 취소 모달 확인 팝업 창. */}
      <Modal isOpen={isModalOpened(MODAL_STACK.RAIN_CANCEL)} toggle={() => closeModal(MODAL_STACK.RAIN_CANCEL)} centered>
        <ModalHeader toggle={() => closeModal(MODAL_STACK.RAIN_CANCEL)} className="border-0">
          <div className="d-flex justify-content-center"></div>
        </ModalHeader>
        <ModalBody className="text-center">
          <div className="d-flex justify-content-center">
            <div className="d-flex flex-column justify-content-center align-items-center" style={{ paddingInline: 18 }}>
              <div className="d-flex justify-content-center">
                <i className="ri-alert-line ri-xl text-danger"></i>
              </div>
              <div className="mt-3" style={{ textAlign: "left" }}>
                <p>안녕하세요, 고객님! 저희는 고객님의 행복한 경험을 위해 예약 당일 우천으로 인한 예약 취소 시 100% 환불 정책을 시행하고 있습니다. </p>
                <p>단, 당일 오전 10시 이후로는 예약 취소가 불가능하오니, 이 점 꼭 참고해 주시기 바랍니다. 비오는 날씨에도 행복한 하루 되세요!</p>
                <br />
                <p>*어메이징캠프 환불정책</p>
                <p>
                  이용일 3일전: 100% 자동환불
                  <br />
                  이용 이틀전 : 70% 환불(30%차감)
                  <br />
                  이용 하루전: 50% 환불
                  <br />
                  이용 당일 : 환불불가
                  <br />
                  -당일 우천 시 이용가능하나, 취소 희망 시
                </p>
                <p>예약 당일 자정부터 오전 10시 이전까지 사이트 마이페이지 구매내역에서 직접 취소하시면 100% 환불 가능합니다. (단, 오전 10시 이후 100% 환불불가입니다.)</p>
                <p>본 메세지는 우천 시 발송되는 메시지입니다. </p>
              </div>
              <div className="mt-3">
                <input type="checkbox" id="cancelableCheck" onChange={(e) => setCancelChecked(true)} />
                <label htmlFor="cancelableCheck" className="ms-2">
                  위 내용을 확인하였습니다.
                </label>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="border-0">
          <div className="d-flex flex-row justify-content-end w-100 gap-3">
            <Button
              color="dark"
              onClick={() => {
                closeModal(MODAL_STACK.RAIN_CANCEL);
              }}
              className="rounded-pill"
            >
              닫기
            </Button>
            <Button
              color="secondary"
              onClick={() => {
                if (cancelChecked) changeCancelableUserConfirm(true);
                else alert("위 내용을 확인해주세요.");
              }}
              className="rounded-pill"
            >
              확인
            </Button>
          </div>
        </ModalFooter>
      </Modal>

      {/* Voucher Modal */}
      <Modal isOpen={isModalOpened(MODAL_STACK.VOUCHER)} backdrop={"static"} id="staticBackdrop" centered>
        <ModalHeader>
          <Button
            type="button"
            className="btn-close"
            onClick={() => {
              closeModal(MODAL_STACK.VOUCHER);
            }}
            aria-label="Close"
          ></Button>
        </ModalHeader>
        <ModalBody className="text-center p-5">
          <div className="mt-4">
            <h5 className="modal-title mb-3" id="staticBackdropLabel">
              교환권이 도착했습니다
            </h5>
            <p className="text-muted mb-4">확인하시겠습니까?</p>
            <div className="hstack gap-2 justify-content-center">
              <Link to="/my-page" state={{ tab: "3" }} className="btn btn-success">
                <i className="healthicons-o-yes me-1 align-middle"></i>확인하러 가기
              </Link>
            </div>
          </div>
        </ModalBody>
      </Modal>

      {/* Announce Modal */}
      {announceDatas.map((announce, idx) => {
        console.log(announce);
        console.log(MODAL_STACK.ANNOUNCE + announce.id);
        return (
          <AnnounceModal
            key={announce.id}
            visible={isModalOpened(MODAL_STACK.ANNOUNCE + announce.id)}
            setVisible={() => closeModal(MODAL_STACK.ANNOUNCE + announce.id)}
            img={announce.image}
            id={announce.id}
            link={announce.link}
          />
        );
      })}

      {/* <div>{JSON.stringify(modalStack)}</div> */}

      {/* Deposit Modal */}
      <Modal isOpen={isModalOpened(MODAL_STACK.DEPOSIT)} backdrop={"static"} id="staticBackdrop-deposit" centered>
        <ModalHeader>
          <Button
            type="button"
            className="btn-close"
            onClick={() => {
              closeModal(MODAL_STACK.DEPOSIT);
            }}
            aria-label="Close"
          ></Button>
        </ModalHeader>
        <ModalBody className="text-center p-5">
          <div className="">
            {todaysReservations
              .filter((reservation) => !!reservation.depositRequired && reservation.reserv_id === depositReservId)
              .map((reservation, idx) => {
                return (
                  <p key={idx}>
                    <span className="fw-bold">{reservation.name}</span> {reservation.reservationDate} {reservation.spot}번
                  </p>
                );
              })}
            <p>체크인 하기전에 청소보증금을 결제해 주세요</p>
            <p style={{ textAlign: "left" }}>
              <i className="ri ri-information-line label-icon align-middle fs-20"></i>&nbsp; 청소보증금이란?
              <br />
              <span style={{ color: "#666" }}>청결한 시설 관리 및 비품 관리를 위해 패키지 상품 구매 시 청소보증금이 결제됩니다. </span>
              시설 이용 후 정리를 완료하고 체크아웃 버튼을 누르면 관리자가 정리 사항을 확인합니다.
              <br />
              <span style={{ color: "red" }}>체크아웃 요청이 승인 되면 자동 환불됩니다.</span>
            </p>
            <p className="text-muted mb-4">결제화면으로 이동하시겠습니까?</p>
            <div className="hstack gap-2 justify-content-center">
              <Link to={`/add-options-order?id=${depositReservId}&deposit=true`} state={{ tab: "3" }} className="btn btn-success">
                <i className="healthicons-o-yes me-1 align-middle"></i>결제하기
              </Link>
            </div>
          </div>
        </ModalBody>
      </Modal>

      <Modal isOpen={isModalOpened(MODAL_STACK.KAKAO_CHANNEL_AGREE)} backdrop={"static"} id="staticBackdrop-deposit" centered>
        <ModalHeader>
          <Button
            type="button"
            className="btn-close"
            onClick={() => {
              closeModal(MODAL_STACK.KAKAO_CHANNEL_AGREE);
            }}
            aria-label="Close"
          ></Button>
        </ModalHeader>
        <ModalBody className="text-center px-5">
          <h4 className="text-center" style={{ fontFamily: "Gmarket-M", color: "#f6475f" }}>
            서비스 추가동의 안내
          </h4>
          <div className="pt-3 pb-2" style={{ textAlign: "left" }}>
            <h5 className="mb-2" style={{ fontFamily: "Gmarket-M" }}>
              안녕하세요, {user?.username}님
            </h5>
            <p className="my-2 fs-14">
              어메이징티켓에서 카카오 로그인을 이용한 고객님에게
              <br />더 나은 서비스를 제공하기 위해 고객님의 동의가 필요합니다.
            </p>
            <p className="my-3 fs-14">고객님의 정보는 아래의 혜택 제공을 위해 사용됩니다.</p>
            <p className="my-2 fs-14">* 맞춤 캠핑장 추천</p>
            <p className="my-2 fs-14">* 특별 할인 및 프로모션 정보</p>
            <p className="my-2 fs-14 mb-3">* 신상품 업데이트</p>
            <p className="my-2 fs-14">
              고객님의 정보는 안전하게 보호됩니다.
              <br />
              언제든 동의를 철회할 수 있습니다.
            </p>

            <p className="my-2 fs-14">동의하기를 누르시면 카카오 로그인으로 이동합니다.</p>
          </div>
          <div className="pt-3 pb-2"></div>
          <Row className="justify-content-center align-items-center">
            {/* <Col>
              <Button
                variant="light"
                className="w-100"
                onClick={() => {
                  onDismiss();
                  close();
                }}
              >
                하루동안 보지 않기
              </Button>
            </Col> */}
            <Col>
              <Button
                style={{ fontFamily: "Gmarket-M", backgroundColor: "#f6475f", borderColor: "#f6475f" }}
                variant="success"
                className="w-100 "
                onClick={() => {
                  closeModal(MODAL_STACK.KAKAO_CHANNEL_AGREE);
                  logOut("/login");
                }}
              >
                동의하기
              </Button>
            </Col>
          </Row>
          <div className="pt-2"></div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default Navbar;
