import React, { useEffect } from "react";
import { Modal, Button, Alert } from "reactstrap";
import { BACKEND_HOST } from "../../api";
import { getAccessToken } from "../../pages/Authentication/auth";
import moment from "moment";
import axios from "axios";

const ShoppingRefundModal = (props) => {
  const togModal = () => {
    props.setVisible(!props.visible);
  };
  const useConfirm = (message = null, onConfirm, onCancel) => {
    if (!onConfirm || typeof onConfirm !== "function") {
      return;
    }
    if (onCancel && typeof onCancel !== "function") {
      return;
    }

    const confirmAction = () => {
      if (window.confirm(message)) {
        onConfirm();
      } else {
        onCancel();
      }
    };

    return confirmAction;
  };

  //   const goCancel = () => {
  //     const accessToken = getAccessToken();
  //     fetch(`${BACKEND_HOST}/shop/order-cancel/`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${accessToken}`,
  //       },
  //       body: JSON.stringify({ id: props.info.id }),
  //     })
  //       .then((response) => response.json())
  //       .then((data) => {
  //         console.log(data);
  //         if (data.result === "ok") {
  //           if (!alert("취소 요청이 처리 되었습니다.")) props.setInfo({});
  //           props.setVisible(false);
  //           props.navigate(0);
  //         } else if (data["error"]) {
  //           alert("취소 요청이 처리되지 않았습니다. " + data["error"]);
  //         } else {
  //           alert("취소 요청이 처리되지 않았습니다. " + data);
  //         }
  //       })
  //       .catch((e) => console.log(e));
  //   };

  const goCancel = async () => {
    const token = getAccessToken();

    const res = await axios.post(
      `${BACKEND_HOST}/shop/order-cancel/`,
      {
        order_id: props.info.id,
        // partial_cancels: [{ id: itemId }],
        cancel_reason: "사용자 취소",
      },
      {
        headers: {
          //   Origin: `https://${props.info.delivery.subdomain}.amazingticket.site`,
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const _json = res.data;
    // console.log(_json);
    if (_json.result !== "ok") {
      console.log("error", _json.error);
      throw new Error(_json.error || "취소 요청이 실패하였습니다.");
    }
    return _json;
  };

  useEffect(() => {
    console.log("info", props.info);
  }, [props.info]);

  return (
    <React.Fragment>
      <Modal toggle={togModal} isOpen={props.visible} modalClassName="fadeInUp" centered>
        <div className="mx-3 my-3 pt-4">
          {/* <h3>티켓 디테일</h3> */}
          <ul className="list-group">
            {/* <li className="list-group-item">환불상품 : {props.info.name}</li> */}
            <li className="list-group-item">
              상품명 :{" "}
              {props.info.shopping_products?.map((product, index) => (
                <>{product.name},</>
              ))}
            </li>

            {props.info.variation ? <li className="list-group-item">상품옵션 : {props.info.variation.value}</li> : null}

            <li className="list-group-item">주문번호 : {props.info?.id}</li>
            <li className="list-group-item">결제일 : {moment(props.info.created_at).format("YYYY-MM-DD HH:mm")}</li>
            <li className="list-group-item">환불금액 : {props.info.amount?.toLocaleString()}원</li>
          </ul>
          {props.info.isCancelable ? (
            <div style={{ marginTop: "10px", paddingLeft: "5px", textAlign: "center", fontFamily: "Gmarket-M" }}>
              배송이 시작된 후 주문취소는 고객센터로 문의 부탁드립니다.
              <br /> 울산점 0507-1423-2223
            </div>
          ) : (
            <div style={{ marginTop: "10px", paddingLeft: "5px", textAlign: "center", fontFamily: "Gmarket-M" }}>
              취소시 주문번호가 같은 상품은 모두 취소 됩니다.
              <br /> 배송이 시작된 후 주문취소는 고객센터로 문의 부탁드립니다.
              <br /> 울산점 0507-1423-2223
            </div>
          )}
        </div>
        <div className="d-flex justify-content-center mx-1 mb-4">
          <Button
            color="light ms-2 my-2"
            style={{ fontFamily: "Gmarket-M", backgroundColor: "#fff", borderColor: "#ff5a5f", color: "#ff5a5f" }}
            onClick={() => {
              props.setInfo({});
              props.setVisible(!props.visible);
            }}
          >
            닫기
          </Button>
          <Button
            style={{ fontFamily: "Gmarket-M", backgroundColor: "#ff5a5f", borderColor: "#ff5a5f", color: "#fff" }}
            color="danger ms-2 my-2"
            onClick={useConfirm(`정말로 취소하시겠습니까?\n 주문이 모두 환불 됩니다.\n 환불금액:${props.info.amount}`, goCancel, () => {})}
            disabled={props.info.isCancelable}
          >
            주문취소
          </Button>
        </div>
      </Modal>
    </React.Fragment>
  );
};
export default ShoppingRefundModal;
