import React from "react";
import { Col, Container, Row } from "reactstrap";

// Import Images
import imgpattern from "../../assets/images/landing/img-pattern.png";

const BgFrame = ({ children }) => {
  return (
    <React.Fragment>
      <section className="section pb-0 " id="hero">
        {/* <div className="bg-overlay bg-overlay-pattern"></div> */}
        <Container style={{ minHeight: "100vh" }}>
          {children}

          {/* <Row className="justify-content-center">
                        <Col sm={12}>
                            <div className='mt-4 mt-sm-5 pt-sm-5 mb-sm-n5 demo-carousel'>
                                <div className="demo-img-patten-top d-none d-sm-block">
                                    <img src={imgpattern} className="d-block img-fluid" alt="..." />
                                </div>
                                <div className="demo-img-patten-bottom d-none d-sm-block">
                                    <img src={imgpattern} className="d-block img-fluid" alt="..." />
                                </div>
                            </div>
                        </Col>
                    </Row> */}
        </Container>
      </section>
    </React.Fragment>
  );
};

export default BgFrame;
