import React from "react";
import { useParams, useLocation } from "react-router-dom";

import Navbar from "../../Components/Common/navbar";
import DetailPage from "./DetailPage";
import { getAccessToken } from "../Authentication/auth";
import { BACKEND_HOST } from "../../api";
import { useUserStore } from "../../store/store";

const Detail = () => {
  const location = useLocation();
  const { type, id } = useParams();

  const [permission] = useUserStore((state) => [state.permission]);

  window.onscroll = function () {
    scrollFunction();
  };

  const scrollFunction = () => {
    const element = document.getElementById("back-to-top");
    if (element) {
      if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
        element.style.display = "block";
      } else {
        element.style.display = "none";
      }
    }
  };

  const toTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  return (
    <React.Fragment>
      <div className="layout-wrapper landing" style={{ backgroundColor: "#fff9fa" }}>
        <div style={{ maxWidth: "640px", margin: "0 auto", backgroundColor: "#fff" }}>
          <Navbar />
          <DetailPage
            type={(() => {
              let type_id = null;
              if (type === "package") type_id = 0;
              else if (type === "ticket") type_id = 1;
              else type_id = 2;
              return type_id;
            })()}
            id={id}
            selectDate={location.state.selectedDate}
            spotId={location.state.selectedSpotId}
            permission={permission}
          />
          {/* <button onClick={() => toTop()}
                    className="btn btn-danger btn-icon landing-back-top"
                    style={{ bottom: "10%" }}
                    id="back-to-top"
                >
                    <i className="ri-arrow-up-line"></i>
                </button> */}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Detail;
