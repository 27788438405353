import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "reactstrap";
import { BACKEND_HOST } from "../../../api";
import { imageUpload } from "../../../common/utils/FileUploader";
import { getAccessToken } from "../../Authentication/auth";
import styled from "styled-components";
import { MdOutlineFileUpload } from "react-icons/md";

const FileLabel = styled.label`
  /* margin-top: 30px; */
  background-color: #f6475f;
  color: #fff;
  text-align: center;
  padding: 8px 0;
  width: 100px;
  border-radius: 6px;
  cursor: pointer;
`;

const RealFileInput = styled.input.attrs({
  type: "file",
  id: "chooseFile",
  accept: "image/*",
})`
  display: none;
`;

function ImagesUploader({ groupKey, userId, onUploaded }) {
  const [previewImages, setPreviewImages] = useState([]);
  const [imageUploadProgress, setImageUploadProgress] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchImages(groupKey);
  }, [groupKey]);

  const fetchImages = (groupKey, updated = false) => {
    const accessToken = getAccessToken();
    if (accessToken) {
      fetch(`${BACKEND_HOST}/inquiry/images/?group_key=${groupKey}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setPreviewImages(data);
          if (updated) onUploaded(data);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const handleUpload = (e) => {
    const file = e.currentTarget.files[0];
    const accessToken = getAccessToken();
    console.log("handleUpload", accessToken);

    setLoading(true);
    imageUpload({
      accessToken,
      url: `${BACKEND_HOST}/inquiry/images/`,
      groupKey,
      userId,
      file,
      name: file.name,
      onUploadProgress: (percentCompleted) => {
        setImageUploadProgress(percentCompleted);
      },
    })
      .then((result) => {
        console.log(result);
        fetchImages(groupKey, true);
        setLoading(false);

        e.currentTarget.value = "";
      })
      .catch((error) => {
        console.warn(error);
      });
  };

  const handleDelete = (imageId) => {
    const accessToken = getAccessToken();
    if (accessToken) {
      fetch(`${BACKEND_HOST}/inquiry/images/${imageId}/`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((result) => {
          fetchImages(groupKey, true);
          console.log(result);
        })
        .catch((error) => {
          console.warn(error);
        });
    }
  };

  return (
    <React.Fragment>
      <Row className="justify-content-center">
        <Col lg={12} className="mb-3">
          <div className="d-flex flex-direction-row gap-3 flex-wrap">
            {previewImages
              ? previewImages.map((_image) => (
                  <div key={_image.id}>
                    <div>
                      <img src={_image.image} style={{ maxWidth: 100 }} />
                    </div>
                    <div style={{ position: "absolute", bottom: 5, paddingLeft: 5 }}>
                      <Button size="sm" className="btn-danger" onClick={() => handleDelete(_image.id)}>
                        삭제
                      </Button>
                    </div>
                  </div>
                ))
              : null}
          </div>
        </Col>
        <Col lg={12}>{loading ? imageUploadProgress : null}</Col>
      </Row>
      <Row className="justify-content-center">
        <Col lg={12}>
          <FileLabel className="file-label" htmlFor="chooseFile">
            {/* <MdOutlineFileUpload size={26} /> */}
            이미지 선택
          </FileLabel>
          <RealFileInput
            className="real-file"
            id="chooseFile"
            type="file"
            accept="image/*"
            onChange={(e) => {
              handleUpload(e);
            }}
          />
          <div style={{ color: "GrayText" }}>한번에 한장씩, 여러 장의 이미지를 업로드 할 수 있습니다.</div>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default ImagesUploader;
