import React, { useState } from "react";
import { Container, Col, Row, Card, CardBody, Button } from "reactstrap";
import { Link, navigate, useNavigate } from "react-router-dom";

import { BACKEND_HOST } from "../../api";
import QuantityButtonInput from "../../Components/Common/QuantityButtonInput";

const CardSection = () => {
  const navigate = useNavigate();

  const [tickets, setTickets] = React.useState([]);

  const [ticketQuantity, setTicketQuantity] = useState([]);

  React.useEffect(() => {
    fetch(`${BACKEND_HOST}/ticket/products/`)
      .then((response) => response.json())
      .then((data) => {
        console.log("get tickets", data);
        setTickets(data);
        setTicketQuantity(
          data.map((item, idx) => {
            return { id: item.id, quantity: 0 };
          })
        );
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const ticket_purchase_count = ticketQuantity
    .map((item) => {
      return item.quantity;
    })
    .reduce((prev, curr, obj) => {
      return prev + curr;
    }, 0);

  return (
    <React.Fragment>
      {/* <div className="mt-5" style={{ height: "30px" }}></div> */}
      <Container>
        <Row className="mt-4 d-flex flex-wrap" style={{ paddingBottom: "160px" }}>
          {tickets.map((item, idx) => {
            return (
              <Col key={idx} xxl={6} xl={6} md={6} sm={6} xs={6}>
                <Card className="">
                  <div
                    onClick={() => {
                      if (item.state !== "품절") navigate(`/detail/ticket/${item.id}`, { state: { selectedDate: null, selectedSpotId: null } });
                    }}
                  >
                    <img className="card-img-top img-fluid" src={`${item.thumbnail}`} alt="Card cap" style={{ height: "200px", objectFit: "contain" }} />
                  </div>
                  <CardBody className="d-flex flex-column justify-content-center align-items-center">
                    <div style={{ fontFamily: "Gmarket-M" }}>{item.name}</div>
                    {/* {(item.remaining_stock != 9999 && item.remaining_stock > 0) ? <div>(남은 타켓: {item.remaining_stock}개)</div> : null} */}
                    <div className="card-text mt-2 mb-3" style={{ fontFamily: "Gmarket-M" }}>
                      {item.price.toLocaleString()} 원
                    </div>
                    <QuantityButtonInput
                      quantity={ticketQuantity[idx].quantity}
                      onChange={(value) => {
                        if (value > item.remaining_stock) {
                          alert("구매 가능 개수를 초과하였습니다.");
                          return;
                        }
                        const _ticketQuantity = [...ticketQuantity];
                        _ticketQuantity[idx].quantity = value;
                        setTicketQuantity(_ticketQuantity);
                      }}
                    />
                    {/* <div> 상태: {item.state}</div> */}
                  </CardBody>
                </Card>
              </Col>
            );
          })}
        </Row>
        <Row
          className="position-fixed d-flex flex-row justify-content-center"
          // style={{ bottom: "20px", left: "calc(50vw + 6px)", width: "calc(100vw - 40px)", transform: "translate(-50%)", zIndex: 999 }}
          style={{ maxWidth: "620px", bottom: "100px", left: "50%", width: "95%", transform: "translate(-50%)", zIndex: 999, margin: "0 auto" }}
        >
          <Button
            disabled={!ticket_purchase_count}
            style={{ border: "none", color: "white", background: "#F6475F", width: "100%", borderRadius: "12px", fontSize: "16px", padding: "12px", fontWeight: "bold" }}
            onClick={() => {
              navigate(`/order-ticket-multi`, {
                state: {
                  products: tickets.map((item, idx) => ({ ...item, quantity: ticketQuantity[idx].quantity })).filter((item) => !!item.quantity),
                },
              });
            }}
          >
            총 {ticket_purchase_count}개 결제하기
          </Button>
        </Row>
      </Container>
    </React.Fragment>
  );
};
export default CardSection;
