import React from "react";

import { useNavigate } from "react-router-dom";
import classnames from "classnames";
import { Card, Nav, Navbar, NavItem, NavLink, Row, Col, CardBody } from "reactstrap";
import ReservationGift from "./ReservationGift";
import UserVoucherGift from "./VoucherGift";
import TicketGift from "./TicketGift";

const GiftTabView = () => {
  const navigate = useNavigate();

  // Custom Tabs Bordered
  const [customActiveTab, setcustomActiveTab] = React.useState("1");
  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  return (
    <div className="layout-wrapper landing">
      <Navbar></Navbar>
      <div>
        <Row className="justify-content-center">
          <Col xxl={12} xl={12} md={12} sm={12}>
            <Card className="bg-white bg-opacity-50">
              <CardBody style={{ padding: 0 }}>
                <Nav tabs className="nav nav-tabs nav-tabs-custom nav-danger nav-justified mb-3 bg-body bg-opacity-10">
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer", fontSize: "0.8rem" }}
                      className={classnames({
                        active: customActiveTab === "1",
                      })}
                      onClick={() => {
                        toggleCustom("1");
                      }}
                    >
                      예약 선물
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer", fontSize: "0.8rem" }}
                      className={classnames({
                        active: customActiveTab === "3",
                      })}
                      onClick={() => {
                        toggleCustom("3");
                      }}
                    >
                      티켓 선물
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer", fontSize: "0.8rem" }}
                      className={classnames({
                        active: customActiveTab === "2",
                      })}
                      onClick={() => {
                        toggleCustom("2");
                      }}
                    >
                      교환권 선물
                    </NavLink>
                  </NavItem>
                </Nav>
              </CardBody>
              <div>
                {customActiveTab === "1" ? <ReservationGift navigate={navigate} /> : null}
                {customActiveTab === "2" ? <UserVoucherGift navigate={navigate} /> : null}
                {customActiveTab === "3" ? <TicketGift navigate={navigate} /> : null}
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default GiftTabView;
