import React, { useEffect, useState } from "react";
import { Modal, Button, Alert, Form, Label, Input } from "reactstrap";
import { Link } from "react-router-dom";
import { getAccessToken, getKakaoAccessToken, KAKAO_AUTH_URL } from "../../pages/Authentication/auth";
import { QRCodeSVG } from "qrcode.react";
import AvatarImg from "../Common/Avatar";
import { BACKEND_HOST } from "../../api";

function telValidator(args) {
  if (/^[0-9]{2,3}-[0-9]{3,4}-[0-9]{4}/.test(args)) return true;
  return false;
}
const confirm = (message = null, onConfirm, onCancel) => {
  if (!onConfirm || typeof onConfirm !== "function") return;
  if (onCancel && typeof onCancel !== "function") return;
  const confirmAction = () => {
    if (window.confirm(message)) onConfirm();
    else onCancel();
  };
  return confirmAction;
};

const TicketGiftModal = ({ info, setInfo, visible, setVisible, needRefresh }) => {
  const togModal = () => setVisible(!visible);

  const [friendsList, setFriendsList] = useState([]);
  const [kakaoFailed, setKakaoFailed] = useState(false);
  const [phoneNum, setPhoneNum] = useState("");

  // const giftTicket = async (phoneNum) => {
  //     const phone_num = phoneNum.replaceAll("-","");
  //     const accessToken = getAccessToken()
  //     const _data = await fetch(`${BACKEND_HOST}/ticket/send-ticket-gift/`, {
  //         method: "POST",
  //         headers: {
  //             "Content-Type": "application/json",
  //             Authorization: `Bearer ${accessToken}`
  //         },
  //         body: JSON.stringify({
  //             "ticketId": info.id,
  //             "phoneNum": phone_num
  //         })
  //     })
  //     const _json = _data.json()
  //     return _json
  // }

  const [phoneNumModal, setPhoneNumModal] = useState(false);
  const togglePhoneNumModal = () => setPhoneNumModal(!phoneNumModal);

  const inviteUserForTicket = async (ticketId, phoneNum) => {
    const phone_num = phoneNum.replaceAll("-", "");

    const accessToken = getAccessToken();
    const _data = await fetch(`${BACKEND_HOST}/ticket/invite-user-for-gift/${ticketId}/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        ticketId: ticketId,
        phoneNum: phone_num,
      }),
    });
    const _json = _data.json();
    return _json;
  };

  const giftTicketToKakaoFriend = async (ticketId, kakaoId) => {
    const accessToken = getAccessToken();
    const _data = await fetch(`${BACKEND_HOST}/ticket/send-ticket-gift-kakao/${ticketId}/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        ticketId: ticketId,
        kakaoId: kakaoId,
      }),
    });
    const _json = _data.json();
    return _json;
  };

  const handleLoadKakaoFriends = () => {
    setFriendsList([]);
    const ktoken = getKakaoAccessToken();
    if (ktoken) {
      fetch("https://kapi.kakao.com/v2/user/scopes", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${ktoken}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          // console.log("kakao scopes", data)
          let userConsent = false;
          data["scopes"].forEach((element) => {
            if (element.id === "friends" && element.agreed) {
              userConsent = true;
            }
            // console.log(element.id, element.agreed, element)
          });

          if (userConsent) {
            console.log("confirm user consent for sharing friends list!");
            fetch("https://kapi.kakao.com/v1/api/talk/friends", {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${ktoken}`,
              },
            })
              .then((res) => res.json())
              .then((data) => {
                // console.log(data)
                if (data["elements"]) {
                  setFriendsList(data["elements"]);
                }
              })
              .catch((e) => {
                console.log(e);
              });
          } else {
            alert("카카오 계정에서 추가 동의가 필요한 기능입니다. 카카오 재로그인이 필요합니다!");
            setKakaoFailed(true);
          }
        })
        .catch((e) => {
          console.log(e);
          alert("카카오 친구 목록을 불러오기를 실패했습니다. 카카오 재로그인이 필요합니다!");
          setKakaoFailed(true);
        });
    } else {
      alert("카카오 친구 목록을 불러오기를 실패했습니다. 카카오 로그인이 필요합니다!");
      setKakaoFailed(true);
    }
  };

  useEffect(() => {
    if (visible) {
      handleLoadKakaoFriends();
    }
  }, [visible]);

  const handleSendGift = async (info, friendId) => {
    const results = [];
    try {
      const result = await giftTicketToKakaoFriend(info.id, friendId);
      results.push({ ...result, id: info.id });
      console.log("send gift done:", info.id, result);
    } catch (error) {
      results.push({ error, id: info.id });
      console.log("send gift error:", info.id, error);
    }
    if (results.filter((item) => item["result"] === "ok").length === 1) alert("선물하기 완료!");
    else alert("선물하기 오류.." + JSON.stringify(results));
    needRefresh();
  };

  useEffect(() => {
    if (phoneNum.length === 10) {
      setPhoneNum(phoneNum.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3"));
    }
    if (phoneNum.length === 13) {
      setPhoneNum(phoneNum.replace(/-/g, "").replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3"));
    }
  }, [phoneNum]);

  return (
    <React.Fragment>
      <Modal toggle={togModal} isOpen={visible} modalClassName="fadeInUp" centered>
        <div className="mx-3 my-3">
          <div className="my-3 d-flex justify-content-center  "></div>
          <ul className="list-group">
            <li className="list-group-item">티켓ID : {info.id}</li>
            <li className="list-group-item">티켓명 : {info.name}</li>
            <li className="list-group-item">결제금액 : {info.amount}</li>
            <li className="list-group-item">상태 : {info.state}</li>
          </ul>

          <div style={{ marginTop: "24px" }}></div>
          <h5>
            <span className="text-dark mb-1 px-2" style={{ fontFamily: "Gmarket-M" }}>
              내 카카오 친구 목록에서 선택하기
            </span>
          </h5>
          {!kakaoFailed ? (
            <div style={{ height: "40vh", overflowY: "scroll" }}>
              <ul className="list-group">
                {friendsList.map((friend, idx) => {
                  return (
                    <li className="list-group-item" key={idx}>
                      <div className="d-flex justify-content-between">
                        <AvatarImg src={friend.profile_thumbnail_image} alt="" size={"32px"} />
                        <span className="text-dark mt-3 mb-1" style={{}}>
                          {friend.profile_nickname}
                        </span>

                        <span style={{ padding: "8px" }}></span>
                        <Button
                          onClick={() => {
                            // console.log("kakao friend:", friend.id, friend.uuid)
                            handleSendGift(info, friend.id);
                            setInfo({});
                            setVisible(false);
                          }}
                          className="btn btn-success ms-2 my-2"
                        >
                          선물하기
                        </Button>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          ) : (
            <div style={{ padding: "8px" }}>
              <div style={{ fontSize: "14px", lineHeight: "24px" }}>
                친구 목록 불러오기를 실패했습니다. <br />
                카카오 로그인을 재시도 해주세요. <br />
                친구 목록 보기 권한 요청 시 수락하여 주세요.
              </div>
              <Button
                style={{ fontFamily: "Gmarket-M", width: "100%" }}
                color="btn btn-warning mt-4"
                onClick={() => {
                  window.location.replace(KAKAO_AUTH_URL);
                }}
              >
                카카오 재로그인
              </Button>
            </div>
          )}

          <div style={{ marginTop: "18px" }}></div>
        </div>

        <div className="d-flex justify-content-center mx-1 pb-4">
          <Button
            style={{ fontFamily: "Gmarket-M", backgroundColor: "#fff", borderColor: "#ff5a5f", color: "#ff5a5f" }}
            color="btn btn-light ms-2 my-2"
            onClick={() => {
              setInfo({});
              setVisible(false);
            }}
          >
            닫기
          </Button>
          <Button
            style={{ fontFamily: "Gmarket-M", backgroundColor: "#ff5a5f", borderColor: "#ff5a5f", color: "#fff" }}
            color="btn btn-soft ms-2 my-2"
            onClick={() => {
              setPhoneNumModal(true);
            }}
          >
            전화번호로 초대하기
          </Button>
        </div>
      </Modal>

      <Modal toggle={togglePhoneNumModal} isOpen={phoneNumModal} modalClassName="fadeInUp" centered>
        <div className="mx-3 my-3">
          <div className="my-3 d-flex justify-content-center">
            <h4 className="mb-sm-0" style={{ fontFamily: "Gmarket-M" }}>
              전화번호로 초대하기
            </h4>
          </div>
          <div style={{ marginTop: "24px" }}></div>
          <div>
            <p style={{ fontSize: "14px", lineHeight: "24px" }}>
              입력하신 전화번호가 기존의 가입된 회원일 경우 티켓이 선물됩니다.
              <br />
              가입되지 않은 전화번호일 경우, 초대장이 전송됩니다.
            </p>
          </div>
          <div style={{ marginTop: "36px" }}></div>
          <Form>
            {/* <Label htmlFor="gift-ticket-phone" className="form-label">
              휴대폰번호
            </Label> */}
            <Input
              value={phoneNum}
              onChange={(e) => {
                setPhoneNum(e.currentTarget.value);
              }}
              type="text"
              className="form-control"
              id="gift-ticket-phone"
              placeholder="휴대폰번호"
            />
            <div style={{ marginTop: "5px", color: "GrayText", marginLeft: "5px" }}>받으실 분의 휴대폰 번호를 입력해 주세요.</div>
          </Form>
        </div>
        <div className="d-flex justify-content-center mx-1">
          <Button
            style={{ fontFamily: "Gmarket-M", backgroundColor: "#fff", borderColor: "#ff5a5f", color: "#ff5a5f" }}
            color="light ms-2 my-2"
            onClick={() => {
              setPhoneNum("");
              setPhoneNumModal(false);
            }}
          >
            닫기
          </Button>
          <Button
            style={{ fontFamily: "Gmarket-M", backgroundColor: "#ff5a5f", borderColor: "#ff5a5f", color: "#fff" }}
            onClick={() => {
              if (!telValidator(phoneNum)) {
                alert("잘못된 번호 형식입니다!");
                return;
              }

              confirm(
                `${phoneNum} 님께 티켓을 선물하시겠습니까?`,
                () => {
                  if (info) {
                    inviteUserForTicket(info.id, phoneNum)
                      .then((data) => {
                        if (data.result === "ok") {
                          setPhoneNum("");
                          setPhoneNumModal(false);
                          setInfo({});
                          setVisible(false);

                          needRefresh();
                        }
                      })
                      .catch((e) => {
                        console.log(e);
                        alert("전송 실패");
                      });
                  }
                },
                () => {}
              )();
            }}
            className="btn btn-success ms-2 my-2"
          >
            초대하기
          </Button>
        </div>
      </Modal>
    </React.Fragment>
  );
};
export default TicketGiftModal;
