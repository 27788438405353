import { create } from "zustand";

export const useUserStore = create((set) => ({
  user: null,
  permission: null,
  isWebPurchase: false,
  isWebPurchaseTicket: false,
  isWebShopping: false,
  isManaged: false,
  adminInquiry: false,
  inquiryQueryStrings: {
    // phone_num: "",
    // search: "",
    // is_notice: "False",
    // mode: "2",
    modes: "1,2,3,7",
    // need_extra_info: "",
    // no_reply: "",
    // my_article: "",
  },
  setUser: (user) => set({ user }),
  logout: () => set({ user: null }),
  setPermission: (permission) => set({ permission }),
  setIsWebPurchase: (isWebPurchase) => set({ isWebPurchase }),
  setIsWebPurchaseTicket: (isWebPurchaseTicket) => set({ isWebPurchaseTicket }),
  setIsWebShopping: (isWebShopping) => set({ isWebShopping }),
  setIsManaged: (isManaged) => set({ isManaged }),
  setAdminInquiry: (adminInquiry) => set({ adminInquiry }),
  setInquiryQueryStrings: (inquiryQueryStrings) => set({ inquiryQueryStrings }),
}));

// user: {
//     "id": ,
//     "phone": "",
//     "username": "",
//     "membership": "일반",
//     "thumbnail_img": "",
//     "profile_img": ",
//     "permission": "관리자"
// }

// permission: {
// permission: 2,
// subdomain: 'test'
// }
