import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Label, CardBody, CardHeader, Input, Button, Spinner } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Select from "react-select";

import MyEditor from "../../../Components/Common/MyEditor";
import { fetchErrorHandling, getAccessToken, getUserId } from "../../Authentication/auth";

import { BACKEND_HOST } from "../../../api";
import { SITE_ID } from "../../../assets/meta/meta";
import ImagesUploader from "./ImagesUploader";
import { useUserStore } from "../../../store/store";
import { IoArrowBackOutline } from "react-icons/io5";
import styled from "styled-components";

const InquiryFormSection = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [user, setUser, permission] = useUserStore((state) => [state.user, state.setUser, state.permission]);
  const [userInfo, setUserInfo] = useState({
    phone_num: null,
  });

  const [title, setTitle] = React.useState("");
  const [content, setContent] = React.useState("");
  const [mapAddress, setMapAddress] = React.useState("");
  const [phoneNum, setPhoneNum] = React.useState("");
  const [userFullName, setUserFullName] = React.useState("");

  const [phoneError, setphoneError] = useState(false);

  const [groupKey, setGroupKey] = useState(Math.random().toString(16).slice(2));
  const [images, setImages] = useState([]);

  const [modeChoices, setModeChoices] = useState([
    {
      value: "2",
      label: "문의",
    },
    // {
    //     value: "3",
    //     label: "리뷰"
    // },
    // {
    //     value: "1",
    //     label: "가맹점 문의"
    // },
    {
      value: "4",
      label: "공지사항",
    },
    {
      value: "5",
      label: "자주묻는질문",
    },
    {
      value: "6",
      label: "분실물",
    },
    // {
    //     value: "7",
    //     label: "기타"
    // },
  ]);

  const [mode, setMode] = useState(modeChoices[0]);

  useEffect(() => {
    const accessToken = getAccessToken();
    if (accessToken && !userInfo.phone_num) {
      let decoded = {};

      const userId = decoded["user_id"];
      // alert("user id: " + userId)

      fetch(`${BACKEND_HOST}/accounts/user-info/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then(async (response) => {
          if (response.ok) {
            return response.json();
          } else {
            const message = response.body || response.status + " An error occurred while fetching the data.";
            throw new Error(message);
          }
        })
        .then((results) => {
          console.log(results);
          setUser(results);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setUserInfo({
        phone_num: null,
      });
    }
  }, []);

  useEffect(() => {
    // if (user && (user.permission === "스탭" || user.permission === "관리자") && !!permission) {
    if (user && permission.permission === 2 && !!permission) {
      console.log("permission11", user);
    } else if (user) {
      if (modeChoices.find((_mode) => _mode.value === "4"))
        setModeChoices((prev) => {
          return [
            {
              value: "2",
              label: "문의",
            },
          ];
        });
    }

    if (user) {
      setUserFullName(user.username);
      setPhoneNum(user.phone);
    }
  }, [user, permission, modeChoices]);

  const fillStates = (data) => {
    setTitle(data.title);
    setContent(data.content);

    setImages(data.images);
    setGroupKey(data.image_group_key);
    setMapAddress(data.address);
    setPhoneNum(data.phone_num);
    setMode(modeChoices.find((_mode) => _mode.value === data.mode));
  };

  const checkData = () => {
    let regExp = /^(01[016789]{1})-?[0-9]{3,4}-?[0-9]{4}$/;
    let result = regExp.test(phoneNum);
    if (phoneNum && !result) setphoneError(true);
    else setphoneError(false);

    if (!title || !content || !userFullName) {
      // if (!title || !mapAddress || !content || !result || !userFullName) {
      return null;
    }

    const userId = getUserId();
    if (userId === null) navigate(`/`);
    const data = {
      site: SITE_ID,
      user: userId,
      title: title,
      address: mapAddress,
      content: content,
      image_group_key: groupKey,
      images: images.map((_image) => _image.id),
      phone_num: phoneNum,
      user_full_name: userFullName,
      mode: mode.value,
    };

    return data;
  };

  const [isSending, setIsSending] = useState(false);

  const sendData = () => {
    const data = checkData();
    console.log("sendData", data);

    if (data === null) {
      alert("입력한 데이터가 정확한지 확인해 주세요.");
      return;
    } else {
      const accessToken = getAccessToken();
      if (!accessToken) {
        alert("로그인이 필요한 서비스입니다.");
        navigate(`/login`);
        return;
      }
      if (accessToken) {
        setIsSending(true);

        fetch(`${BACKEND_HOST}/inquiry/article-write/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(data),
        })
          .then((response) => response.json())
          .then((data) => {
            console.log("inquiry/article-write", data);

            alert("문의 등록 완료");
            resetForms();
            navigate(`/inquiry`);
          })
          .catch((e) => console.log(e))
          .finally(() => setIsSending(false));
      }
    }
  };

  const updateData = () => {
    const data = checkData();
    if (data === null) {
      alert("제목과 내용 모두 입력해주세요.");
      return;
    } else {
      const accessToken = getAccessToken();
      if (accessToken) {
        setIsSending(true);
        fetch(`${BACKEND_HOST}/inquiry/article-write/${id}/`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(data),
        })
          .then((response) => response.json())
          .then((data) => {
            alert("수정 완료");
            resetForms();
            navigate(`/inquiry`);
          })
          .catch((e) => console.log(e))
          .finally(() => setIsSending(false));
      }
    }
  };

  const onImageUploaded = (data) => {
    setImages(data);
  };

  React.useEffect(() => {
    if (id !== "0") {
      const accessToken = getAccessToken();
      if (accessToken) {
        fetch(`${BACKEND_HOST}/inquiry/articles/${id}/`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
          .then((response) => response.json())
          .then((data) => {
            console.log("inquiry/articles", data);
            fillStates(data);
          })
          .catch((e) => {
            console.log(e);
          });
      }
    } else {
      resetForms();
    }
  }, [id]);

  const resetForms = () => {
    setGroupKey(Math.random().toString(16).slice(2));
    setTitle("");
    setContent("");
    setMapAddress("");
    setPhoneNum("");
  };

  useEffect(() => {
    if (phoneNum) {
      if (phoneNum.length === 10) {
        setPhoneNum(phoneNum.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3"));
      }
      if (phoneNum.length === 13) {
        setPhoneNum(phoneNum.replace(/-/g, "").replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3"));
      }
    }
  }, [phoneNum]);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      // 다른 스타일들을 여기에 추가할 수 있어
    }),
    option: (provided) => ({
      ...provided,
      // 다른 옵션의 스타일들을 여기에 추가할 수 있어
    }),
    singleValue: (provided, state) => {
      const color = state.isSelected ? "red" : provided.color; // 선택된 옵션인지 확인
      return { ...provided, color };
    },
  };

  const StyledSelect = styled(Select).attrs({
    classNamePrefix: "react-select",
  })`
    /* .react-select__control {
      background-color: #fa5938;
      width: 100px;
      height: 40px;
      padding-right: 15px;
      border: none;
      border-radius: 20px;
      display: flex;
      text-align: center;
      cursor: pointer;
    } */
    .react-select__single-value {
    }
    .react-select__menu {
      background-color: #ffffff;
      border-radius: 4px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      /* font-weight: 600; */
      text-align: center;
    }
    .react-select__option {
      background-color: transparent; /* option 배경색 */
      color: black; /* option 텍스트 색상 */
    }
    .react-select__option--is-selected {
      background-color: #f6475f; /* 클릭된 option 배경색 */
      color: #fff; /* 클릭된 option 텍스트 색상 */
    }
    .react-select__option--is-focused {
      border: none;
      color: black; /* hover 상태의 option 텍스트 색상 */
    }
    .react-select__placeholder {
      color: white;
      font-weight: 600;
    }
  `;

  return (
    <div className="mt-0">
      <Container>
        <div style={{ backgroundColor: "#fff", padding: "10px 0" }} className="sticky-top">
          <Button
            color="white"
            className="add-btn"
            style={{ backgroundColor: "transparent", boxShadow: "none", border: "none" }}
            onClick={() => {
              navigate(`/inquiry`);
            }}
            id="edit-btn"
          >
            <IoArrowBackOutline size={26} />
          </Button>
          <h4
            style={{
              fontSize: "16px",
              fontFamily: "Gmarket-M",
              position: "absolute",
              margin: "0 auto",
              marginBottom: "0",
              textAlign: "center",
              left: "49%",
              top: "50%",
              transform: "translate(-50%,-50%)",
            }}
          >
            문의 등록 및 수정
          </h4>
        </div>

        <Row className="justify-content-center" style={{ minHeight: "100vh" }}>
          <Col lg={12}>
            <Card id="customerList">
              <CardBody>
                <Row>
                  <Col md={12}>
                    <div className="mb-3">
                      <Label htmlFor="article-mode" className="form-label">
                        *분류
                      </Label>
                      <StyledSelect
                        defaultValue={mode[0]}
                        value={mode}
                        styles={customStyles}
                        onChange={(value) => {
                          setMode(value);
                        }}
                        options={modeChoices}
                        name="article-mode"
                        id="article-mode"
                      ></StyledSelect>
                    </div>
                    {/* <div className="mb-3">
                      <Label htmlFor="title" className="form-label">
                        *제목
                      </Label>
                      <Input type="text" className="form-control" placeholder="제목" id="title" value={title} onChange={(e) => setTitle(e.target.value)} maxLength="100" />
                    </div> */}
                    {/* <div className="mb-3">
                                            <Label htmlFor="mapAddress" className="form-label">주소</Label>
                                            <Input type="text" className="form-control" placeholder="주소입력" id="mapAddress" value={mapAddress} onChange={(e) => (setMapAddress(e.target.value))} maxLength="200" />
                                        </div> */}
                    {/* <div className="mb-3">
                                            <Label htmlFor="userFullName" className="form-label">이름(*)</Label>
                                            <Input type="text" className="form-control" placeholder="이름입력" id="userFullName" value={userFullName} onChange={(e) => (setUserFullName(e.target.value))} maxLength="50" />
                                        </div> */}
                    {/* <div className="mb-3">
                                            <Label
                                                htmlFor="inquiry-phone"
                                                className="form-label"
                                            >
                                                휴대폰번호
                                            </Label>
                                            <Input
                                                value={phoneNum}
                                                onChange={(e) => {
                                                    setPhoneNum(e.currentTarget.value)
                                                }}
                                                type="text"
                                                className={`form-control ${phoneError ? "border-danger" : ""}`}
                                                id="inquiry-phone"
                                                placeholder="휴대폰번호"
                                            />
                                            {phoneError ? <span style={{ color: "red" }}>전화번호 양식이 정확하지 않습니다.</span> : null}
                                        </div> */}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>

          <Col lg={12} className="mb-3" style={{ paddingLeft: "28px" }}>
            <ImagesUploader groupKey={groupKey} userId={getUserId()} onUploaded={onImageUploaded} />
          </Col>

          <Col lg={12}>
            <Card id="customerList">
              {/* <CardBody>
                <Row>
                  <Col md={12}>
                    <div className="mb-3">
                      <Label htmlFor="title" className="form-label ">
                        *제목
                      </Label>
                      <div className="border-bottom my-2"></div>
                      <Input type="text" className="form-control mt-4" placeholder="제목" id="title" value={title} onChange={(e) => setTitle(e.target.value)} maxLength="100" />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardHeader><h4 className="card-title mb-0">*내용</h4></CardHeader> */}
              <CardBody>
                <Row>
                  <Col md={12}>
                    <Input
                      type="text"
                      style={{ padding: "12px", fontSize: "15px" }}
                      className="form-control mt-4"
                      placeholder="제목"
                      id="title"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      maxLength="100"
                    />
                    <div className="border-bottom my-4"></div>
                    <MyEditor value={content} onChange={setContent} />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col lg={12} className="mb-5">
            {isSending && (
              <div className="d-flex justify-content-sm-end gap-2">
                <Button className="add-btn me-1" disabled style={{ border: "none", fontSize: "13px", fontFamily: "Gmarket-M", backgroundColor: "#f6475f", color: "#fff" }}>
                  <Spinner size="sm" color="light" /> 등록중
                </Button>
              </div>
            )}
            {!isSending && (
              <div className="d-flex justify-content-end gap-2">
                {id !== "0" ? (
                  <Button
                    className="add-btn me-1"
                    style={{ border: "none", fontSize: "13px", fontFamily: "Gmarket-M", backgroundColor: "#f6475f", color: "#fff" }}
                    onClick={() => {
                      updateData();
                    }}
                    id="create-btn"
                  >
                    수정하기
                  </Button>
                ) : (
                  <Button
                    style={{ border: "none", fontSize: "13px", fontFamily: "Gmarket-M", backgroundColor: "#f6475f", color: "#fff" }}
                    className="add-btn me-1"
                    onClick={() => {
                      sendData();
                    }}
                    id="create-btn"
                  >
                    등록하기
                  </Button>
                )}
                <Button
                  style={{ border: "1px solid #f6475f", fontSize: "13px", fontFamily: "Gmarket-M", backgroundColor: "#fff", color: "#f6475f" }}
                  className="add-btn me-1"
                  onClick={() => {
                    navigate(`/inquiry`);
                  }}
                  id="cancel-btn"
                >
                  취소하기
                </Button>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default InquiryFormSection;
