import React from "react";
import { Modal, Button, Alert } from "reactstrap";
import { Link } from "react-router-dom";
import { getAccessToken } from "../../pages/Authentication/auth";
import { QRCodeSVG } from "qrcode.react";
import { BACKEND_HOST } from "../../api";

const TicketGiftUseModal = ({ setVisible, visible, info, setInfo, needRefresh }) => {
  const [checkModal, setCheckModal] = React.useState(false);
  const [checkQrCode, setCheckQrCode] = React.useState("");

  const togModal = () => {
    setVisible(!visible);
    setCheckQrCode("");
  };

  const togCheckModal = () => {
    setCheckModal(!checkModal);
  };

  const changeTicket = async () => {
    const accessToken = getAccessToken();
    const _data = await fetch(`${BACKEND_HOST}/ticket/use-ticket-gift/${info.id}/`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      // body: JSON.stringify({id: info.id, ticket: "입장"})
    });
    const _json = _data.json();
    return _json;
  };

  const getQrCode = async () => {
    const accessToken = getAccessToken();
    const _data = await fetch(`${BACKEND_HOST}/ticket/ticket-qr/${info.ticket_id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const _json = _data.json();
    return _json;
  };

  return (
    <React.Fragment>
      <Modal toggle={togModal} isOpen={visible} modalClassName="fadeInUp" centered>
        <div className="mx-3 my-3">
          {/* qr코드 받아올 시에 그려야함 */}
          <div className="my-3 d-flex justify-content-center  ">
            {checkQrCode ? (
              <QRCodeSVG value={checkQrCode} />
            ) : (
              <Button
                onClick={() => {
                  getQrCode().then((obj) => {
                    console.log(obj);
                    setCheckQrCode(obj.qr);
                  });
                }}
                className="btn btn-warning"
              >
                QR 생성하기
              </Button>
            )}
          </div>
          <ul className="list-group">
            <li className="list-group-item">티켓ID : {info.ticket_id}</li>
            <li className="list-group-item">티켓명 : {info.name}</li>
            <li className="list-group-item">상태 : {info.ticket_state}</li>
          </ul>
        </div>
        <div className="d-flex justify-content-center mx-1 pb-4">
          <Button
            style={{ fontFamily: "Gmarket-M", backgroundColor: "#fff", borderColor: "#ff5a5f", color: "#ff5a5f" }}
            color="light ms-2 my-2"
            onClick={() => {
              setInfo({});
              setVisible(!visible);
              setCheckQrCode("");
            }}
          >
            닫기
          </Button>
          <Button
            style={{ fontFamily: "Gmarket-M", backgroundColor: "#ff5a5f", borderColor: "#ff5a5f" }}
            // onClick={() => {
            //   setVisible(!visible);
            //   setCheckModal(!checkModal);
            // }}
            onClick={() => {
              const confirmReserv = window.confirm("정말 사용하시겠습니까?");
              if (confirmReserv) {
                changeTicket().then((obj) => {
                  alert("티켓 상태가 변경되었습니다!");
                  setVisible(!visible);
                  // alert("예약 상태가 변경되었습니다! " + JSON.stringify(obj))
                  needRefresh();
                });
              }
            }}
            className="btn btn-success ms-2 my-2"
          >
            직원확인
          </Button>
        </div>
      </Modal>
      {/* <Modal toggle={togCheckModal} isOpen={checkModal} centered>
        <div className="my-4 mx-2 px-4" style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
          <h5 className="modal-title mb-3" id="staticBackdropLabel" style={{ fontFamily: "Gmarket-M" }}>
            티켓 사용
          </h5>
          <p className="text-muted mb-4">정말 사용하시겠습니까?</p>
          <div className="hstack gap-2 justify-content-center">
            <Button
              to="#"
              className="btn btn-success"
              style={{ fontFamily: "Gmarket-M", backgroundColor: "#ff5a5f", borderColor: "#ff5a5f", color: "#fff" }}
              onClick={() => {
                setCheckModal(false);
                changeTicket().then((obj) => {
                  // console.log(obj)
                  needRefresh();
                });
              }}
            >
              <i className="ri-check-line me-1 align-middle "></i>사용하기
            </Button>
            <Button
              style={{ fontFamily: "Gmarket-M", backgroundColor: "#fff", borderColor: "#ff5a5f", color: "#ff5a5f" }}
              to="#"
              className="btn fw-medium"
              onClick={() => {
                setCheckModal(false);
              }}
            >
              <i className="ri-close-line me-1 align-middle"></i> 사용안함
            </Button>
          </div>
        </div>
      </Modal> */}
    </React.Fragment>
  );
};
export default TicketGiftUseModal;
