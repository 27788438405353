import React from "react";
import { Modal, Button, Alert } from "reactstrap";
import { BACKEND_HOST } from "../../api";
import { getAccessToken } from "../../pages/Authentication/auth";

const PurchaseTicketModal = (props) => {
  const togModal = () => {
    props.setVisible(!props.visible);
  };
  const useConfirm = (message = null, onConfirm, onCancel) => {
    if (!onConfirm || typeof onConfirm !== "function") {
      return;
    }
    if (onCancel && typeof onCancel !== "function") {
      return;
    }

    const confirmAction = () => {
      if (window.confirm(message)) {
        onConfirm();
      } else {
        onCancel();
      }
    };

    return confirmAction;
  };

  const goCancel = () => {
    const accessToken = getAccessToken();
    fetch(`${BACKEND_HOST}/ticket/cancel-ticket/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ id: props.info.id }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.result === "ok") {
          if (!alert("취소 요청이 처리 되었습니다.")) props.setInfo({});
          props.setVisible(false);
          props.navigate(0);
        } else if (data["error"]) {
          alert("취소 요청이 처리되지 않았습니다. " + data["error"]);
        } else {
          alert("취소 요청이 처리되지 않았습니다. " + data);
        }
      })
      .catch((e) => console.log(e));
  };

  return (
    <React.Fragment>
      <Modal toggle={togModal} isOpen={props.visible} modalClassName="fadeInUp" centered>
        <div className="mx-3 my-3 pt-4">
          {/* <h3>티켓 디테일</h3> */}
          <ul className="list-group">
            <li className="list-group-item">예약상품 : {props.info.name + "(" + props.info.bundleCount + ")"}</li>
            <li className="list-group-item">만료일 : {props.info.expiration_date}</li>
            <li className="list-group-item">결제일 : {props.info.orderDate}</li>
            <li className="list-group-item">환불금액 : {props.info.amount}</li>
          </ul>
          {props.info.isCancelable ? (
            <div style={{ marginTop: "10px", paddingLeft: "5px", textAlign: "center", fontFamily: "Gmarket-M" }}>티켓 번들중 하나라도 사용하면 모두 남은 번들도 모두 환불 불가 합니다.</div>
          ) : (
            <div style={{ marginTop: "10px", paddingLeft: "5px", textAlign: "center", fontFamily: "Gmarket-M" }}>환불시 다른 티켓 번들 모두 환불 됩니다.</div>
          )}
        </div>
        <div className="d-flex justify-content-center mx-1 mb-4">
          <Button
            color="light ms-2 my-2"
            style={{ fontFamily: "Gmarket-M", backgroundColor: "#fff", borderColor: "#ff5a5f", color: "#ff5a5f" }}
            onClick={() => {
              props.setInfo({});
              props.setVisible(!props.visible);
            }}
          >
            닫기
          </Button>
          <Button
            style={{ fontFamily: "Gmarket-M", backgroundColor: "#ff5a5f", borderColor: "#ff5a5f", color: "#fff" }}
            color="danger ms-2 my-2"
            onClick={useConfirm(`정말로 취소하시겠습니까?\n 번들 모두가 환불 됩니다.\n 환불금액:${props.info.amount}`, goCancel, () => {})}
            disabled={props.info.isCancelable}
          >
            환불
          </Button>
        </div>
      </Modal>
    </React.Fragment>
  );
};
export default PurchaseTicketModal;
